import React from "react";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Box,
  IconButton,
  Button,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const ProductCard = ({
  product,
  quantityNormal,
  quantityMembership,
  totalQuantity,
  handleAddToCart,
  handleIncrease,
  handleDecrease,
}) => {
  return (
    <Card className="products-top__card" sx={{ maxWidth: 200 }}>
      <Box position="relative">
        <CardMedia
          component="img"
          height="133"
          image={product.image}
          alt={product.name}
          sx={{ objectFit: "cover" }}
        />
        { product.type !== "Convencional" && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            backgroundColor: "green",
            color: "white",
            fontWeight: "bold",
            padding: "4px 8px",
            borderRadius: "4px",
          }}
        >
          {product.type}
        </Box>
        )}
      </Box>
      <CardContent>
        <Typography variant="caption" color="textSecondary">
          {product.brand}
        </Typography>
        <Typography
          variant="body2"
          fontSize="0.75rem"
          color="textPrimary"
          fontWeight="bold"
          mt={1}
        >
          {product.name}
        </Typography>
        <Typography variant="body1" fontSize="1.1rem" color="green" fontWeight="bold" mt={1}>
          ${product.price_membership.toLocaleString("es-CL")}{" "}
          <span style={{ fontSize: "0.8rem", color: "gray" }}>Membresía</span>
        </Typography>
        <Typography variant="body1" fontSize="1rem" color="textPrimary" mt={1}>
          ${product.price.toLocaleString("es-CL")}{" "}
          <Typography component="span" fontSize="0.8rem" color="textSecondary">
            precio normal
          </Typography>
        </Typography>
        {quantityMembership > 0 && (
          <Typography variant="body2" color="green">
            Membresía: {quantityMembership}
          </Typography>
        )}
        {quantityNormal > 0 && (
          <Typography variant="body2" color="red">
            Normal: {quantityNormal}
          </Typography>
        )}
        {totalQuantity === 0 ? (
          <Button
            onClick={() => handleAddToCart(product)}
            variant="contained"
            color="success"
            fullWidth
            sx={{ mt: 1 }}
          >
            Agregar
          </Button>
        ) : (
          <div
            className="quantity-selector"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "10px",
            }}
          >
            <IconButton
              onClick={() => handleDecrease(product)}
              sx={{
                color: "white",
                backgroundColor: "green",
                "&:hover": { backgroundColor: "#66bb6a" },
              }}
            >
              <RemoveIcon />
            </IconButton>
            <Typography
              sx={{ width: "50px", textAlign: "center", backgroundColor: "#f5f5f5" }}
            >
              {totalQuantity}
            </Typography>
            <IconButton
              onClick={() => handleIncrease(product)}
              sx={{
                color: "white",
                backgroundColor: "green",
                "&:hover": { backgroundColor: "#66bb6a" },
              }}
            >
              <AddIcon />
            </IconButton>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default ProductCard;
