import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Box,
  Typography,
  AppBar,
  Toolbar,
  useMediaQuery,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  Fab,
  ListItemIcon,
  Avatar,
  TextField,
  Paper,
  InputAdornment,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import MenuIcon from "@mui/icons-material/Menu";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import SavingsIcon from "@mui/icons-material/Savings";
import "./Header.scss";
import CartDrawer from "../CartPage/CartDrawer";
import logo from "../../assets/temp/logo-header-takay.png";
import UserProfileMenu from "../UserProfileMenu/UserProfileMenu";
import Auth from "../Auth/Auth";
import { AuthContext } from "../../context/AuthContext";
import { CartContext } from "../../context/CartContext";
import logoMovil from "../../assets/temp/takay-movil.png";
import CartIcon from "../Cart/CartIcon";
import CloseIcon from "@mui/icons-material/Close";
import SpaIcon from "@mui/icons-material/Spa"; 
import GrainIcon from "@mui/icons-material/Grain"; 
import EggIcon from "@mui/icons-material/Egg"; 
import KitchenIcon from "@mui/icons-material/Kitchen";
import { getProducts } from "../../client/apiProducts";
import SearchIcon from "@mui/icons-material/Search";
import ListAlt from "@mui/icons-material/ListAlt"; // Importa el ícono de pedidos
import LocationOn from "@mui/icons-material/LocationOn";


export const Header = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [openLoginOptions, setOpenLoginOptions] = useState(false);
  const [openCartDrawer, setOpenCartDrawer] = useState(false);
  const [openCategoriesDrawer, setOpenCategoriesDrawer] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredSubcategories, setFilteredSubcategories] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const { user, logout, balance } = useContext(AuthContext);
  const { cartItems, totalSavings, totalPrice } = useContext(CartContext);
  const navigate = useNavigate(); // Add navigate for redirection

  const isMobile = useMediaQuery("(max-width: 1024px)");
  const loginOptionsRef = useRef(null);

  const calculateTotalSavings = () => {
    return cartItems
      .filter(item => item.isMembership) // Filtrar solo productos con membresía
      .reduce((total, item) => {
        const normalPrice = parseFloat(item.price_normal || item.price); // Precio normal
        const membershipPrice = parseFloat(item.price_membership); // Precio de membresía
        const itemSavings = (normalPrice - membershipPrice) * item.quantity; // Ahorro por producto
        return total + itemSavings;
      }, 0);
  };

  const formatPrice = (price) => {
    return new Intl.NumberFormat("es-CL", { style: "currency", currency: "CLP" }).format(price);
  };

  const handleOpenLoginModal = () => {
    setOpenLoginModal(true);
    setOpenLoginOptions(false);
  };

  const handleCloseLoginModal = () => setOpenLoginModal(false);

  const handleOpenCartDrawer = () => setOpenCartDrawer(true);

  const handleCloseCartDrawer = () => setOpenCartDrawer(false);

  const handleOpenCategoriesDrawer = () => setOpenCategoriesDrawer(true);

  const handleCloseCategoriesDrawer = () => setOpenCategoriesDrawer(false);

  const handleLogout = () => {
    logout();
    handleCloseCategoriesDrawer();
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (loginOptionsRef.current && !loginOptionsRef.current.contains(event.target)) {
        setOpenLoginOptions(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [loginOptionsRef]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const products = await getProducts();
        setAllProducts(products);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };
    fetchProducts();
  }, []);

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
  
    if (value.length >= 2) {
      const matchingSubcategories = allProducts
        .filter((product) => product.subCategory.toLowerCase().includes(value.toLowerCase()))
        .map((product) => product.subCategory);
  
      setFilteredSubcategories([...new Set(matchingSubcategories)]);
    } else {
      setFilteredSubcategories([]);
    }
  };

  const handleSubcategorySelect = (subcategory) => {
    navigate(`/filtered-products?searchTerm=${subcategory}`); // Redirect to the filtered products page
    setFilteredSubcategories([]);
    setSearchTerm("");
  };

  const hasNormalPriceItems = cartItems.some((item) => !item.isMembership);

  const categories = [
    { name: "Frutas y Verduras", path: "/filtered-products/Frutas y Verduras", icon: <SpaIcon sx={{ color: "#4caf50" }} />  },
    { name: "Frutos Secos, Cereales y Granos", path: "/filtered-products/Frutos secos, Cereales y Granos", icon: <GrainIcon sx={{ color: "#4caf50" }} /> },
    { name: "Huevos y Otras Proteinas", path: "/filtered-products/Huevos y Otras Proteinas", icon: <EggIcon sx={{ color: "#4caf50" }} /> },
    { name: "Legumbres y Otros Productos de Despensa", path: "/filtered-products/Legumbres y otros productos de Despensa", icon: <KitchenIcon sx={{ color: "#4caf50" }} /> },
  ];

  return (
    <>
      <AppBar
        position="sticky"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: "#5fa801" }}
      >
        <Toolbar sx={{ justifyContent: "space-between" }}>
          

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Link to="/" style={{ textDecoration: "none", display: 'flex', alignItems: 'center' }}>
              <img src={isMobile ? logoMovil : logo} alt="T'akay Logo" style={{ height: isMobile ? 40 : 60 }} />
            </Link>

            {isMobile && (balance === 0 || !user) && (
              <Fab
              variant="extended"
              size="small"
              sx={{
                ml: 1,
                backgroundColor: "orange",
                color: "white",
                "&:hover": {
                  backgroundColor: "#e69500",
                },
                fontWeight: "bold",
                height:35
              }}
              onClick={() => navigate("/membership")} // Redirige a la página de membresía
            >
              <Typography variant="button" sx={{ textAlign: "center", width: "90%",fontSize:11 }}>
                Obtener Membresía
              </Typography>
            </Fab>
            )}


            {!isMobile && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: "center",
                  ml: 0.5,
                  cursor: "pointer",
                  padding: '0px 5px',
                  borderRadius: '8px',
                  transition: 'background-color 0.3s, color 0.3s',
                  "&:hover": {
                    backgroundColor: "#4a7c34",
                    color: "#ffffff",
                  },
                }}
                onClick={handleOpenCategoriesDrawer}
              >
                <MenuIcon />
                <Typography variant="h6" sx={{ ml: 0.2, color: "white" }}>
                  Categorías
                </Typography>
              </Box>
            )}

            {!isMobile && (
              <TextField
                variant="outlined"
                placeholder="Busca en T'akay"
                value={searchTerm}
                onChange={handleSearchChange}
                sx={{
                  ml: 2,
                  width: 300,
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "50px",
                    backgroundColor: "#ffffff",
                    height: 36,
                    padding: "0 10px",
                  },
                  "& .MuiOutlinedInput-input": {
                    padding: "8px 0",
                    fontSize: "0.875rem",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={{ color: "#4caf50" }} />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          </Box>

          {!isMobile && filteredSubcategories.length > 0 && (
            <Paper 
              elevation={3}
              sx={{
                position: "absolute",
                top: "65px", 
                left: "280px",
                width: 300,
                maxHeight: 200,
                overflowY: "auto",
                zIndex: 1301 
              }}
            >
              <List>
                {filteredSubcategories.map((subcategory, index) => (
                  <ListItem key={index} button onClick={() => handleSubcategorySelect(subcategory)}>
                    <ListItemText primary={subcategory} />
                  </ListItem>
                ))}
              </List>
            </Paper>
          )}

          {!isMobile && user && (
            <Box sx={{ display: "flex", justifyContent: "center", mx: 3, gap: 2 }}> {/* gap añade espacio entre los Fab */}
              <Fab
                variant="extended"
                sx={{
                  backgroundColor: "#ae5b04",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#5fa801",
                  },
                }}
              >
                <MonetizationOnIcon sx={{ mr: 1 }} />
                <Typography variant="h6" sx={{ fontSize: "1rem" }}>
                  {`Saldo de membresía: $${balance.toLocaleString("es-CL")}`}
                </Typography>
              </Fab>
                {
                  calculateTotalSavings()>0 && (
                <Fab
                  variant="extended"
                  sx={{
                    backgroundColor: "orange",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#5fa801",
                    },
                  }}
                >
                  <SavingsIcon sx={{ mr: 1 }} /> {/* Cambiamos el icono al de ahorro */}
                  <Typography variant="h6" sx={{ fontSize: "1rem" }}>
                    Ahorro total: {formatPrice(calculateTotalSavings())}
                  </Typography>
                </Fab>
                  )
                }
            </Box>
          )}
          {isMobile && user && (
            <Box sx={{ display: 'flex', gap: 1, ml: 1 }}>
            <Fab
              variant="extended"
              size="small"
              sx={{
                backgroundColor: "#ae5b04",
                color: "white",
                "&:hover": {
                  backgroundColor: "#5fa801",
                },
                height: 35,
              }}
            >
              <Typography variant="h1" sx={{ textAlign: "center", width: "90%", fontSize: 11 }}>
                {`Saldo: $${balance.toLocaleString("es-CL")}`}
              </Typography>
            </Fab>

            <Fab
              variant="extended"
              size="small"
              sx={{
                backgroundColor: "orange",
                color: "white",
                "&:hover": {
                  backgroundColor: "#e69500",
                },
                height: 35,
              }}
            >
              <Typography variant="h1" sx={{ textAlign: "center", width: "90%", fontSize: 11 }}>
                Ahorro: {formatPrice(calculateTotalSavings())}
              </Typography>
            </Fab>
          </Box>
          )
        }

          {!isMobile && hasNormalPriceItems && (
            <Box sx={{ display: "flex", justifyContent: "center", mx: 3, gap: 2 }}>
              <Link to="/membership" style={{ textDecoration: "none" }}>
                <Fab
                  variant="extended"
                  sx={{
                    backgroundColor: "#ff6666",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#ff3333",
                    },
                  }}
                >
                  <SavingsIcon sx={{ mr: 1 }} />
                  <Typography variant="h6" sx={{ fontWeight: "bold", fontSize: "1rem" }}>
                    Ahorra: ${totalSavings.toLocaleString("es-CL")} con membresía
                  </Typography>
                </Fab>
              </Link>
            </Box>
          )}
          

          <Box sx={{ display: "flex", alignItems: "center", ml: 3 }}>
            <div className="header__cart">
              <CartIcon className="header__cart-icon" onClick={handleOpenCartDrawer} />
              {isMobile && !user && (
              <IconButton
                color="inherit"
                onClick={handleOpenLoginModal}
                sx={{ ml: 1, marginTop: -1 }}
              >
                <PersonIcon />
              </IconButton>
            )}
            </div>

            {!isMobile && (
              <>
                {user ? (
                  <UserProfileMenu authenticatedUser={user} onLogout={logout} />
                ) : (
                  <Button
                    onClick={handleOpenLoginModal}
                    variant="contained"
                    style={{ backgroundColor: "orange", color: "white" }}
                    startIcon={<PersonIcon />}
                  >
                    INGRESAR
                  </Button>
                )}
              </>
            )}
          </Box>
        </Toolbar>
        {isMobile && (
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleOpenCategoriesDrawer}
              sx={{ mr: 1, marginLeft:1 }}
            >
              <MenuIcon />
            </IconButton>
            <TextField
              variant="outlined"
              placeholder="Busca en T'akay"
              value={searchTerm}
              onChange={handleSearchChange}
              sx={{
                width: "80%",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "50px",
                  backgroundColor: "#ffffff",
                  height: 36,
                  padding: "0 10px",
                },
                "& .MuiOutlinedInput-input": {
                  padding: "8px 0",
                  fontSize: "0.875rem",
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: "#4caf50" }} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        )}

        {isMobile && filteredSubcategories.length > 0 && (
          <Paper
            elevation={3}
            sx={{
              position: "absolute",
              top: "100px",  // Ajusta la posición dependiendo de la vista móvil
              left: "16px",
              width: "90%",  // Asegúrate de que el ancho sea apropiado para vista móvil
              maxHeight: 200,
              overflowY: "auto",
              zIndex: 1301,
            }}
          >
            <List>
              {filteredSubcategories.map((subcategory, index) => (
                <ListItem key={index} button onClick={() => handleSubcategorySelect(subcategory)}>
                  <ListItemText primary={subcategory} />
                </ListItem>
              ))}
            </List>
          </Paper>
        )}
      </AppBar>

      <Auth open={openLoginModal} onClose={handleCloseLoginModal} />
      <Drawer
        anchor="left"
        open={openCategoriesDrawer}
        onClose={handleCloseCategoriesDrawer}
        PaperProps={{
          sx: {
            width: 300,
            borderTopRightRadius: 20,
            borderBottomRightRadius: 20,
            padding: 2,
            height: 'calc(100% - 100px)',
            mt: '64px',
            position: 'fixed',
            top: isMobile ? '35px' : '0',
          },
        }}
      >
      <Box sx={{ position: "relative", height: "100%", display: 'flex', flexDirection: 'column' }}>
        <IconButton
          onClick={handleCloseCategoriesDrawer}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
          }}
        >
          <CloseIcon />
        </IconButton>

        {isMobile && user && (
          <Box
            sx={{
              backgroundColor: "#FFE0B2",
              p: 2,
              mb: 2,
              borderRadius: 1,
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              cursor: 'pointer'
            }}
            onClick={() => {
              navigate("/account");
              handleCloseCategoriesDrawer();
            }}
          >
            <Avatar sx={{ bgcolor: "#FFA726" }}>{user ? user.charAt(0) : "U"}</Avatar>
            <Box>
              <Typography variant="subtitle1" fontWeight="bold">
                {user || "Usuario"}
              </Typography>
            </Box>
          </Box>
        )}

        {/* Sección de categorías */}
        <Typography variant="h5" sx={{ mb: 2 }}>
          Categorías
        </Typography>
        <Divider />

    <List sx={{ flexGrow: 1 }}> {/* Aumenta el espacio disponible en la lista */}
      {categories.map((category) => (
        <ListItem button key={category.name}>
          <Link
            to={category.path}
            style={{ textDecoration: "none", color: "inherit", width: "100%", display: "flex", alignItems: "center" }}
            onClick={handleCloseCategoriesDrawer}
          >
            <ListItemIcon sx={{ minWidth: "30px" }}>
              {category.icon}
            </ListItemIcon>
            <ListItemText primary={category.name} />
          </Link>
        </ListItem>
      ))}
    </List>

    {/* Botón de cerrar sesión */}
    {isMobile && user && (
      <Box sx={{ width: "100%", padding: "0 16px", mb: 4 }}>
        <Button
          variant="contained"
          color="error"
          fullWidth
          onClick={handleLogout}
        >
          Cerrar Sesión
        </Button>
      </Box>
    )}
  </Box>
</Drawer>

      <CartDrawer open={openCartDrawer} onClose={handleCloseCartDrawer}>
        <List>
          {cartItems.length > 0 ? (
            cartItems.map((item) => (
              <ListItem key={item.id}>
                <ListItemText primary={item.name} secondary={`Cantidad: ${item.quantity}`} />
                <Typography>${parseFloat(item.price).toLocaleString("es-CL")}</Typography>
              </ListItem>
            ))
          ) : (
            <Typography variant="body2" sx={{ mt: 2 }}>
              Tu carrito está vacío.
            </Typography>
          )}
        </List>
        <Box sx={{ mt: 2 }}>
          <Typography variant="h6">Total: ${totalPrice().toLocaleString("es-CL")}</Typography>
        </Box>
      </CartDrawer>
    </>
  );
};

export default Header;
