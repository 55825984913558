import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  IconButton,
  Button,
  useMediaQuery,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";

const InsufficientBalanceDialog = ({
  open,
  onClose,
  onAddAtNormalPrice,
  isMobile,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={true}
      maxWidth="sm"
      PaperProps={{
        sx: {
          margin: isMobile ? "10px" : "32px",
          padding: isMobile ? "8px" : "16px",
          borderRadius: "12px",
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
          maxWidth: isMobile ? "95%" : "600px",
        },
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          padding: isMobile ? "8px 16px" : "16px 24px",
          borderBottom: "1px solid #e0e0e0",
        }}
      >
        <DialogTitle
          sx={{
            flexGrow: 1,
            fontSize: isMobile ? "0.9rem" : "1.25rem",
            textAlign: isMobile ? "center" : "left",
          }}
        >
          Saldo de membresía insuficiente
        </DialogTitle>
        {!isMobile && (
          <IconButton onClick={onClose} sx={{ color: "gray" }}>
            <CloseIcon />
          </IconButton>
        )}
      </Box>

      <DialogContent
        sx={{
          padding: isMobile ? "8px 16px" : "16px 24px",
          textAlign: "center",
        }}
      >
        <DialogContentText sx={{ fontSize: isMobile ? "0.8rem" : "1rem" }}>
          Has alcanzado el límite de tu saldo de membresía. Obtén tu membresía y
          sigue disfrutando de grandes descuentos.
        </DialogContentText>
      </DialogContent>

      <DialogActions
        sx={{
          justifyContent: "center",
          flexDirection: "column",
          gap: "8px",
          padding: isMobile ? "8px 16px" : "16px 24px",
          width: "100%",
        }}
      >
        <Button
          onClick={onAddAtNormalPrice}
          sx={{
            color: "green",
            borderColor: "green",
            backgroundColor: "white",
            width: "100%",
            fontSize: isMobile ? "0.8rem" : "1rem",
            padding: isMobile ? "6px 12px" : "8px 16px",
          }}
          variant="outlined"
        >
          Comprar a precio normal
        </Button>
        <Link to="/membership" style={{ textDecoration: "none", width: "100%" }}>
          <Button
            sx={{
              width: "100%",
              color: "white",
              backgroundColor: "green",
              fontSize: isMobile ? "0.8rem" : "1rem",
              padding: isMobile ? "6px 12px" : "8px 16px",
              "&:hover": {
                backgroundColor: "#388e3c",
              },
            }}
            variant="contained"
          >
            Obtener Membresía
          </Button>
        </Link>
      </DialogActions>
    </Dialog>
  );
};

export default InsufficientBalanceDialog;
