import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Button,
} from "@mui/material";

export const ManageOrdersPage = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState({});
  const [decision, setDecision] = useState({});

  // Simulate fetching data or use your API call here
  useEffect(() => {
    const fetchOrders = async () => {
      // Simulated fetch response. Replace with your actual API call
      const response = [
        {
          id: 1,
          customer: { name: "Customer A", whatsapp: "+123456789", email: "customerA@mail.com" },
          product: { name: "Product A", sku: "SKU123" },
          quantity: 2,
          status: "Manage",
        },
        {
          id: 2,
          customer: { name: "Customer B", whatsapp: "+987654321", email: "customerB@mail.com" },
          product: { name: "Product B", sku: "SKU456" },
          quantity: 1,
          status: "Manage",
        },
      ];

      setOrders(response);
    };

    fetchOrders();
  }, []);

  const handleDecision = (orderId, decisionType) => {
    setDecision((prev) => ({
      ...prev,
      [orderId]: decisionType,
    }));
  };

  const handleConfirm = async (orderId) => {
    setLoading((prev) => ({ ...prev, [orderId]: true }));

    try {
      // Simulate API call. Replace with your actual logic
      console.log(`Order ${orderId} confirmed with decision: ${decision[orderId]}`);
      alert(`Order ${orderId} confirmed.`);
    } catch (error) {
      console.error(`Error confirming order ${orderId}:`, error);
    } finally {
      setLoading((prev) => ({ ...prev, [orderId]: false }));
    }
  };

  return (
    <Box sx={{ width: "100%", maxWidth: 800, mx: "auto", p: 2 }}>
      <Typography variant="h4" align="center" sx={{ mb: 4 }}>
        Orders to Manage
      </Typography>
      <Grid container spacing={2}>
        {orders.map((order) => (
          <Grid item xs={12} key={order.id}>
            <Card
              sx={{
                border: "1px solid #ff9800",
                boxShadow: "0 3px 5px rgba(0, 0, 0, 0.2)",
                "&:hover": {
                  boxShadow: "0 6px 10px rgba(0, 0, 0, 0.3)",
                },
              }}
            >
              <CardContent>
                <Typography variant="h6">Order ID: {order.id}</Typography>
                <Typography variant="body2">
                  <strong>Product:</strong> {order.product.name} (SKU: {order.product.sku})
                </Typography>
                <Typography variant="body2">
                  <strong>Quantity:</strong> {order.quantity}
                </Typography>
                <Typography variant="body2">
                  <strong>Customer:</strong> {order.customer.name}
                </Typography>
                <Typography variant="body2">
                  <strong>WhatsApp:</strong> {order.customer.whatsapp}
                </Typography>
                <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
                  <Button
                    variant={decision[order.id] === "Replace" ? "contained" : "outlined"}
                    color="primary"
                    onClick={() => handleDecision(order.id, "Replace")}
                  >
                    Replace
                  </Button>
                  <Button
                    variant={decision[order.id] === "Do Not Replace" ? "contained" : "outlined"}
                    color="secondary"
                    onClick={() => handleDecision(order.id, "Do Not Replace")}
                  >
                    Do Not Replace
                  </Button>
                </Box>
                <Box sx={{ mt: 2 }}>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={() => handleConfirm(order.id)}
                    disabled={loading[order.id]}
                  >
                    {loading[order.id] ? "Confirming..." : "Confirm"}
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
