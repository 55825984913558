import React from "react";
import { Box, Button } from "@mui/material";

const SubgroupButtons = ({ allSubGroups, subGroupName, handleSubGroupChange, selectedCategories }) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      flexWrap="wrap"
      gap={2}
      mt={2}
      sx={{
        width: "70%",
        margin: "auto",
        padding: "10px",
      }}
    >
      {allSubGroups.map((subgroupObj, idx) => {
        const subgroupName = Object.keys(subgroupObj)[0];
        const isAnyCategorySelected = subgroupObj[subgroupName].some((category) =>
          selectedCategories.includes(category)
        );
        
        return (
          <Button
            key={idx}
            variant={subgroupName === subGroupName || isAnyCategorySelected ? "contained" : "outlined"}
            color="success"
            sx={{
              marginRight: 1,
              minWidth: "120px",
              flexGrow: 1,
              flexBasis: "calc(25% - 8px)",
              maxWidth: "200px",
              textAlign: "center",
              backgroundColor: isAnyCategorySelected ? "green" : "white",
              color: isAnyCategorySelected ? "white" : "green",
            }}
            onClick={() => handleSubGroupChange(subgroupName)}
          >
            {subgroupName}
          </Button>
        );
      })}
    </Box>
  );
};

export default SubgroupButtons;
