import React from 'react';
import { RadioGroup, Card, CardContent, FormControlLabel, Radio, Typography, Box } from '@mui/material';
import webpayLogo from '../../assets/logo-webpay-plus.png';

const PaymentMethods = ({ paymentMethod, handlePaymentMethodChange }) => {
  return (
    <Box>
      <Typography variant="h6" gutterBottom>Selecciona un Método de Pago</Typography>
      <RadioGroup value={paymentMethod} onChange={handlePaymentMethodChange}>
        <Card variant="outlined" sx={{ marginBottom: 2 }}>
          <CardContent>
            <FormControlLabel
              value="transfer"
              control={<Radio />}
              label={
                <Typography variant="body1">
                  Transferencia Bancaria <br />
                  Realiza el pago directamente desde tu cuenta bancaria.
                </Typography>
              }
            />
          </CardContent>
        </Card>

        <Card variant="outlined" sx={{ marginBottom: 2 }}>
          <CardContent>
            <FormControlLabel
              value="webpay"
              control={<Radio />}
              label={
                <Box display="flex" alignItems="center">
                  <Box
                    component="img"
                    src={webpayLogo} // Usamos el logo importado como fuente
                    alt="Webpay Logo"
                    sx={{ width: 200, height: 80, marginRight: 1 }}
                  />
                  <Typography variant="body1">
                    Webpay <br />
                    Paga con tarjeta de crédito o débito a través de Webpay.
                  </Typography>
                </Box>
              }
            />
          </CardContent>
        </Card>
      </RadioGroup>
    </Box>
  );
};

export default PaymentMethods;
