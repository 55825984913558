import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Container,
  useMediaQuery,
  Grid,
  Button,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { AuthContext } from "../../context/AuthContext";
import Auth from "./../Auth/Auth";
import { getMembershipsTypes } from "../../client/apiMemberships";
import { createTransactionMembership } from "../../client/apiWebpayService";
import webpayImage from "../../assets/logo-webpay-plus.png";
import { useNavigate } from "react-router-dom"; // Importar useNavigate

export const MembershipPage = () => {
  const { user, userId } = useContext(AuthContext);
  const [authOpen, setAuthOpen] = useState(false);
  const [memberships, setMemberships] = useState([]);
  const [selectedMembership, setSelectedMembership] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState("webpay");
  const [filter, setFilter] = useState("MENSUAL");
  const navigate = useNavigate(); // Inicializar navigate

  const isMobile = useMediaQuery("(max-width: 600px)");

  useEffect(() => {
    const fetchMemberships = async () => {
      try {
        const data = await getMembershipsTypes();
        setMemberships(data);
      } catch (error) {
        console.error("Error loading memberships:", error);
      }
    };

    fetchMemberships();
  }, []);

  const calculateCartTotalAndSave = () => {
    const cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];

    const total = cartItems.reduce((sum, item) => {
      if (item.isMembership) {
        return sum + item.price_membership * item.quantity;
      }
      return sum;
    }, 0);

    localStorage.setItem("tempCartTotal", total);

    return total;
  };

  const handleCheckout = async () => {
    if (!user) {
      setAuthOpen(true);
      return;
    }

    calculateCartTotalAndSave();

    if (!selectedMembership) {
      alert("Por favor, selecciona una membresía.");
      return;
    }

    try {
      localStorage.setItem("membershipId", selectedMembership.id);
      localStorage.setItem("userId", userId);

      if (paymentMethod === "webpay") {
        const response = await createTransactionMembership(selectedMembership.amount);
        if (response.url && response.token) {
          window.location.href = `${response.url}?token_ws=${response.token}`;
        }
      } else {
        // Redirigir a la página de instrucciones de transferencia bancaria
        navigate("/transfer-membership"); // Redirige a la página TransferPaymentInfo
      }
    } catch (error) {
      console.error("Error initiating the transaction:", error);
    }
  };

  const handleMembershipClick = (membership) => {
    setSelectedMembership(membership);
    // Resetear método de pago cuando se cambia de membresía
    //setPaymentMethod("transfer");
  };

  const handlePaymentMethodChange = (value) => {
    setPaymentMethod(value);
  };

  const handleFilterChange = (event, newFilter) => {
    if (newFilter !== null) {
      setFilter(newFilter);
    }
  };

  const filteredMemberships = memberships.filter(
    (membership) => membership.type === filter
  );

  // Definir métodos de pago. Transferencia solo si el filtro es ANUAL
  const paymentMethods = [
    { value: "webpay", label: "", description: "", image: webpayImage },
    { value: "transfer", label: "Transferencia Bancaria", description: "Realiza el pago directamente desde tu cuenta bancaria."},
  ];

  return (
    <>
      <Container
        sx={{
          mt: 4,
          mb: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 3,
        }}
      >
        <Typography
          variant="h4"
          component="h1"
          sx={{
            textAlign: "center",
            fontSize: isMobile ? "1.5rem" : "2.5rem",
            fontWeight: "bold",
            color: "green", 
          }}
        >
          Selecciona tu Membresía
        </Typography>

        <ToggleButtonGroup
          value={filter}
          exclusive
          onChange={handleFilterChange}
          aria-label="filtro de membresía"
          sx={{ mb: 4 }}
        >
          <ToggleButton
            value="MENSUAL"
            aria-label="mensual"
            sx={{
              "&.Mui-selected": {
                bgcolor: "lightgreen", 
                color: "black",
              },
            }}
          >
            Mensual
          </ToggleButton>
          <ToggleButton
            value="ANUAL"
            aria-label="anual"
            sx={{
              "&.Mui-selected": {
                bgcolor: "lightgreen", 
                color: "black",
              },
            }}
          >
            Anual
          </ToggleButton>
        </ToggleButtonGroup>
          <Typography
            variant="subtitle1"
            sx={{
              textAlign: "center",
              color: "green", 
            }}
          >
            Comprando el año ahorras 2 meses de membresía.
          </Typography>
        <Grid container spacing={4} justifyContent="center">
          {filteredMemberships.map((membership) => (
            <Grid item xs={12} sm={6} md={4} key={membership.id}>
              <Card
                variant="outlined"
                onClick={() => handleMembershipClick(membership)}
                sx={{
                  transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  backgroundColor: selectedMembership?.id === membership.id ? "#d4edda" : "white",
                  borderColor: selectedMembership?.id === membership.id ? "green" : "grey",
                  "&:hover": {
                    transform: "translateY(-5px)",
                    boxShadow: 6,
                    cursor: "pointer",
                  },
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "100%",
                }}
              >
                <CardContent sx={{ textAlign: "center" }}>
                  <Box>
                    <Typography
                      variant="h6"
                      component="h3"
                      sx={{ fontSize: "1.2rem", fontWeight: "bold", color: "green" }} 
                    >
                      {membership.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        mb: 1,
                        fontSize: "1rem",
                        backgroundColor: "#FFE4B2", 
                        borderRadius: "10px", 
                        padding: "5px", 
                      }}
                    >
                      <strong>${Number(membership.amount).toLocaleString("es-CL")}</strong>
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ mt: 1, fontSize: "0.9rem" }}
                    >
                      {membership.description}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        <Box sx={{ mt: 4, width: "100%", textAlign: "center" }}>
          <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
            Selecciona el Método de Pago
          </Typography>
          <Grid container spacing={2} justifyContent="center">
            {paymentMethods.map((method) => (
              <Grid item xs={12} sm={6} md={4} key={method.value}>
                <Card
                  variant="outlined"
                  onClick={() => handlePaymentMethodChange(method.value)}
                  sx={{
                    p: 2,
                    transition: "transform 0.3s ease, box-shadow 0.3s ease",
                    backgroundColor:
                      paymentMethod === method.value ? "#FFE4B2" : "white",
                    borderColor:
                      paymentMethod === method.value ? "orange" : "grey",
                    "&:hover": {
                      transform: "translateY(-5px)",
                      boxShadow: 6,
                      cursor: "pointer",
                    },
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center", 
                    height: "100%",
                  }}
                >
                  {method.image && (
                    <img
                      src={method.image}
                      alt={method.label}
                      style={{
                        width: "300px",
                        marginBottom: "10px",
                        height: "80px",
                      }} // Ajustar el tamaño de la imagen
                    />
                  )}

                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    {method.label}
                  </Typography>
                  <Typography variant="body2" sx={{ mt: 1 }}>
                    {method.description}
                  </Typography>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>

        <Box sx={{ mt: 4, textAlign: "center", width: "100%" }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Total: $
            {selectedMembership
              ? Number(selectedMembership.amount).toLocaleString("es-CL")
              : "0"}
          </Typography>
          <Button
            variant="contained"
            sx={{
              bgcolor: "green",
              color: "white",
              fontSize: "1rem",
              fontWeight: "bold",
              "&:hover": { bgcolor: "darkgreen" },
            }}
            onClick={handleCheckout}
            disabled={!selectedMembership || !paymentMethod}
          >
            Comprar
          </Button>
        </Box>
      </Container>

      <Auth open={authOpen} onClose={() => setAuthOpen(false)} />
    </>
  );
};
