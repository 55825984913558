import React from "react";
import {
  Box,
  Typography,
  IconButton,
  Drawer,
  Collapse,
  FormControlLabel,
  Checkbox,
  Divider,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const FilterDrawer = ({
  isDrawerOpen,
  handleDrawerToggle,
  allSubGroups,
  expandedGroups,
  handleGroupToggle,
  selectedCategories,
  handleCategoryChange,
}) => {
  return (
    <Drawer
      anchor="left"
      open={isDrawerOpen}
      onClose={handleDrawerToggle}
      PaperProps={{
        sx: {
          borderRadius: "0 10px 10px 0",
          padding: 2,
          mt: 8,
          marginTop: 12,
        },
      }}
    >
      <Box width={250} role="presentation">
        <Typography variant="h6" gutterBottom>
          Filtros
        </Typography>
        {allSubGroups.map((subGroup, index) => {
          const groupName = Object.keys(subGroup)[0];
          const categories = subGroup[groupName];
          const isExpanded = expandedGroups[groupName] || false;

          return (
            <Box key={index} mb={1}>
              <Box
                display="flex"
                alignItems="center"
                onClick={() => handleGroupToggle(groupName)}
                sx={{ cursor: "pointer" }}
              >
                <IconButton size="small" sx={{ color: "green" }}>
                  <ExpandMoreIcon
                    style={{
                      transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)",
                    }}
                  />
                </IconButton>
                <Typography variant="subtitle1" sx={{ ml: 1 }}>
                  {groupName}
                </Typography>
              </Box>
              <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                <Box ml={4}>
                  {categories.map((category, idx) => (
                    <FormControlLabel
                      key={idx}
                      control={
                        <Checkbox
                          value={category}
                          onChange={handleCategoryChange}
                          checked={selectedCategories.includes(category)}
                        />
                      }
                      label={category}
                    />
                  ))}
                </Box>
              </Collapse>
            </Box>
          );
        })}
      </Box>
    </Drawer>
  );
};

export default FilterDrawer;
