import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Box, Button, Divider } from '@mui/material';

export const OrderDetailDialog = ({ open, onClose, order }) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Detalles de la compra</DialogTitle>
      <DialogContent>
        {order.saleDetails.map((detail) => (
          <Box key={detail.id} sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
            <img
              src={detail.product.url_img}
              alt={detail.product.name}
              style={{ width: '100px', height: '100px', objectFit: 'cover', marginRight: '20px' }}
            />
            <Box>
              <Typography variant="h6">{detail.product.name}</Typography>
              <Typography variant="body2">
                Precio Unitario: ${detail.unit_price.toLocaleString('es-CL')}
              </Typography>
              <Typography variant="body2">
                Cantidad: {detail.quantity}
              </Typography>
              <Typography variant="body2">
                Subtotal: ${detail.subtotal.toLocaleString('es-CL')}
              </Typography>
              <Typography variant="body2">
                ¿Membresía?: {detail.is_membership ? 'Sí' : 'No'}
              </Typography>
            </Box>
          </Box>
        ))}
        <Divider sx={{ my: 2 }} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
