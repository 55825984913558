import axios from 'axios';
import { API_URL } from '../const/api';

const API = `${API_URL}/webpay`;

export const createTransactionSales = async (amount) => {
  const response = await axios.post(`${API}/sales`, { amount });
  return response.data;
};

export const commitTransactionSales = async (token, sales) => {
  const response = await axios.post(`${API}/commitSales`, { token, sales });
  return response.data;
};

export const createTransactionMembership = async (amount) => {
  const response = await axios.post(`${API}/create`, { amount });
  return response.data;
};

export const commitTransactionMembership = async (token, membershipTypeId, clientId) => {
  const response = await axios.post(`${API}/commit`, { token, membershipTypeId, clientId });
  return response.data;
};
