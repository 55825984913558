import React, { useState, useEffect } from 'react';
import { TextField, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { resetPassword } from "../../client/apiAuth"; // Cambiar a la función correcta de la API
import { useNavigate } from 'react-router-dom'; // Para redirigir

export const ChangePasswordPage = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [token, setToken] = useState('');
  const navigate = useNavigate(); // Para redirigir al usuario

  // Extraer el token de la URL cuando el componente se monta
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const tokenFromUrl = queryParams.get('token');
    if (tokenFromUrl) {
      setToken(tokenFromUrl);
    } else {
      setErrorMessage('Invalid or missing token');
      setErrorDialogOpen(true);
    }
  }, []);

  const handleSubmit = async () => {
    if (newPassword !== confirmPassword) {
      setErrorMessage('Las contraseñas no coinciden');
      setErrorDialogOpen(true);
      return;
    }

    try {
      // Llama a la API para cambiar la contraseña
      await resetPassword(token, newPassword);
      setSuccessDialogOpen(true); // Mostrar diálogo de éxito
    } catch (error) {
      setErrorMessage('Error al actualizar contraseña. Prueba nuevamente.');
      setErrorDialogOpen(true); // Mostrar diálogo de error
    }
  };

  const handleCloseErrorDialog = () => {
    setErrorDialogOpen(false);
  };

  const handleCloseSuccessDialog = () => {
    setSuccessDialogOpen(false);
    // Redirigir a la vista principal (cambia '/home' a la ruta deseada)
    navigate('/');
  };

  return (
    <div style={{ maxWidth: '400px', margin: '0 auto', padding: '20px' }}>
      <h2>Cambio de contraseña</h2>
      <TextField
        label="Nueva contraseña"
        type="password"
        fullWidth
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
        margin="normal"
      />
      <TextField
        label="Confirma contraseña"
        type="password"
        fullWidth
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        margin="normal"
      />
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleSubmit}
        style={{ marginTop: '20px' }}
      >
        Cambiar
      </Button>

      {/* Diálogo de error */}
      <Dialog open={errorDialogOpen} onClose={handleCloseErrorDialog}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <DialogContentText>{errorMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseErrorDialog} color="primary">OK</Button>
        </DialogActions>
      </Dialog>

      {/* Diálogo de éxito */}
      <Dialog open={successDialogOpen} onClose={handleCloseSuccessDialog}>
        <DialogTitle>¡Éxito!</DialogTitle>
        <DialogContent>
          <DialogContentText>Tu contraseña ha sido cambiada correctamente. Serás redirigido a la vista principal.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSuccessDialog} color="primary">OK</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
