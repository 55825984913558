// CartDialogs.js
import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";

export const CartDialogs = ({
  validationDialogOpen,
  onValidationDialogClose,
  validationMessage,
  openInsufficientBalanceDialog,
  onInsufficientBalanceDialogClose,
}) => {
  return (
    <>
      {/* Dialogo de Validación */}
      <Dialog open={validationDialogOpen} onClose={onValidationDialogClose}>
        <DialogTitle>Error de Validación</DialogTitle>
        <DialogContent>
          <DialogContentText>{validationMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onValidationDialogClose} color="primary">
            Entendido
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialogo de saldo insuficiente */}
      <Dialog open={openInsufficientBalanceDialog} onClose={onInsufficientBalanceDialogClose}>
        <DialogTitle>Saldo de membresía insuficiente</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Has alcanzado el límite de tu saldo de membresía. Los próximos productos se agregarán a precio normal.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onInsufficientBalanceDialogClose} color="primary">
            Entendido
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
