import React, { useState, useEffect } from 'react';
import { Box, Menu, MenuItem, Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import { useNavigate } from 'react-router-dom';

const UserProfileMenu = ({ authenticatedUser, onLogout }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [userEmail, setUserEmail] = useState('');
  const navigate = useNavigate();

  const adminEmails = ['jorge.antilef@takayapp.cl', 'lucas@takayapp.cl', 'mariajose@takayapp.cl'];

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleNavigateToAccount = () => {
    handleMenuClose();
    navigate('/account');
  };

  const handleLogoutAndClose = () => {
    handleMenuClose();
    onLogout();
  };

  const handleNavigateToAdmin = () => {
    handleMenuClose();
    navigate('/admin');
  };

  useEffect(() => {
    const user = localStorage.getItem('user');
    if (user) {
      const parsedUser = JSON.parse(user);
      setUserEmail(parsedUser.email);
    }

    const handleClickOutside = (event) => {
      if (anchorEl && !anchorEl.contains(event.target)) {
        handleMenuClose();
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [anchorEl]);

  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{
        backgroundColor: "#333",
        color: "white",
        padding: "8px 16px",
        borderRadius: 2,
        cursor: 'pointer',
      }}
      onClick={handleMenuOpen}
    >
      <PersonIcon sx={{ marginRight: 1 }} />
      <Typography>
        ¡Hola, {authenticatedUser}!
      </Typography>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        MenuListProps={{
          sx: {
            padding: 0,
          },
        }}
      >
        <MenuItem
          onClick={handleNavigateToAccount}
          sx={{
            padding: '4px 16px',
            minHeight: 'auto',
          }}
        >
          Mi cuenta
        </MenuItem>

        {adminEmails.includes(userEmail) && (
          <MenuItem
            onClick={handleNavigateToAdmin}
            sx={{
              padding: '4px 16px',
              minHeight: 'auto',
            }}
          >
            Administración
          </MenuItem>
        )}

        <MenuItem
          onClick={handleLogoutAndClose}
          sx={{
            padding: '4px 16px',
            minHeight: 'auto',
          }}
        >
          Cerrar sesión
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default UserProfileMenu;
