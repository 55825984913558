import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Box,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  useMediaQuery,
} from "@mui/material";
import { update } from "../../client/apiMemberships"; // Asegúrate de tener la API correcta

const UpdateMembershipStatus = () => {
  const [status, setStatus] = useState("Activa");
  const [membershipId, setMembershipId] = useState("");
  const isMobile = useMediaQuery("(max-width: 600px)");

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleMembershipIdChange = (event) => {
    setMembershipId(event.target.value);
  };

  const handleSubmit = async () => {
    if (!membershipId) {
      alert("Por favor, completa todos los campos.");
      return;
    }

    try {
      const response = await update(membershipId);
        alert("Estado de membresía actualizado exitosamente.");
        setMembershipId("");
        setStatus("");
    } catch (error) {
      console.error("Error al actualizar el estado de membresía:", error);
      alert("Error al actualizar el estado de membresía.");
    }
  };

  return (
    <Card
      sx={{
        width: isMobile ? "100%" : "50%",
        padding: isMobile ? 2 : 4,
        boxShadow: 3,
        margin: "auto",
      }}
    >
      <CardContent>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
          <TextField
            label="ID de la Membresía"
            variant="outlined"
            fullWidth
            value={membershipId}
            onChange={handleMembershipIdChange}
            sx={{ mb: 2 }}
          />
          <FormControl fullWidth>
            <InputLabel>Estado</InputLabel>
            <Select value={status} onChange={handleStatusChange} label="Estado">
              <MenuItem value={"Activa"}>Activa</MenuItem>
              {/* <MenuItem value={"Expirada"}>Expirada</MenuItem>
              <MenuItem value={"Suspendida"}>Suspendida</MenuItem> */}
            </Select>
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSubmit}
            sx={{
              bgcolor: "green",
              color: "white",
              fontWeight: "bold",
              "&:hover": {
                bgcolor: "darkgreen",
              },
            }}
          >
            Actualizar Estado de Membresía
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default UpdateMembershipStatus;
