// InsufficientBalanceDialog.js
import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Button,
  Box,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export const InsufficientBalanceDialog = ({
  open,
  onClose,
  onAddAtNormalPrice,
  onOpenMembershipModal,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
      PaperProps={{
        sx: {
          padding: "16px",
          borderRadius: "12px",
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
          maxWidth: isMobile ? "90vw" : "400px",
          maxHeight: isMobile ? "80vh" : "auto",
          margin: "auto",
        },
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          paddingBottom: "8px",
          borderBottom: "1px solid #e0e0e0",
        }}
      >
        <DialogTitle sx={{ fontSize: "1.125rem", textAlign: "center", flexGrow: 1, padding: 0 }}>
          Saldo de membresía insuficiente
        </DialogTitle>
        <IconButton onClick={onClose} sx={{ color: "gray", padding: "4px" }}>
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent sx={{ padding: "16px 24px" }}>
        <DialogContentText sx={{ fontSize: "1rem", textAlign: "center" }}>
          Has alcanzado el límite de tu saldo de membresía. Obtén tu membresía y sigue disfrutando de grandes descuentos.
        </DialogContentText>
      </DialogContent>

      <DialogActions
        sx={{
          flexDirection: "column",
          gap: "8px",
          padding: "0 24px 16px",
          width: "100%",
        }}
      >
        <Button
          onClick={onAddAtNormalPrice}
          sx={{
            color: "green",
            borderColor: "green",
            backgroundColor: "white",
            width: "100%",
            fontSize: "1rem",
            padding: "10px",
          }}
          variant="outlined"
        >
          Comprar a precio normal
        </Button>
        <Button
          onClick={onOpenMembershipModal}
          sx={{
            width: "100%",
            color: "white",
            backgroundColor: "green",
            fontSize: "1rem",
            padding: "10px",
          }}
          variant="contained"
        >
          Obtener Membresía
        </Button>
      </DialogActions>
    </Dialog>
  );
};
