import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Box,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  useMediaQuery,
} from "@mui/material";
import { updateSalesOrder } from "../../client/apiSalesOrder";

const ChangeOrderStatus = () => {
  const [status, setStatus] = useState("");
  const [orderId, setOrderId] = useState("");
  const isMobile = useMediaQuery("(max-width: 600px)");

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleOrderIdChange = (event) => {
    setOrderId(event.target.value);
  };

  const handleSubmit = async () => {
    if (!orderId || !status) {
      alert("Por favor, completa todos los campos.");
      return;
    }

    try {
      const response = await updateSalesOrder(orderId, status);
      if (response.success) {
        alert("Estado actualizado exitosamente.");
        setOrderId("");
        setStatus("");
      } else {
        alert("Error actualizando el estado.");
      }
    } catch (error) {
      console.error("Error al actualizar el estado:", error);
      alert("Error al actualizar el estado.");
    }
  };

  return (
    <Card
      sx={{
        width: isMobile ? "100%" : "50%",
        padding: isMobile ? 2 : 4,
        boxShadow: 3,
        margin: "auto",
      }}
    >
      <CardContent>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
          <TextField
            label="ID del Pedido"
            variant="outlined"
            fullWidth
            value={orderId}
            onChange={handleOrderIdChange}
            sx={{ mb: 2 }}
          />
          <FormControl fullWidth>
            <InputLabel>Estado</InputLabel>
            <Select value={status} onChange={handleStatusChange} label="Estado">
              <MenuItem value={"Pendiente de pago"}>Pendiente de pago</MenuItem>
              <MenuItem value={"Pagado"}>Pagado</MenuItem>
              <MenuItem value={"En ruta"}>En ruta</MenuItem>
              <MenuItem value={"Domicilio sin moradores"}>Domicilio sin moradores</MenuItem>
              <MenuItem value={"En preparacion"}>En preparacion</MenuItem>
              <MenuItem value={"Cancelado"}>Cancelado</MenuItem>
              <MenuItem value={"Reagendar"}>Reagendar</MenuItem>
            </Select>
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSubmit}
            sx={{
              bgcolor: "green",
              color: "white",
              fontWeight: "bold",
              "&:hover": {
                bgcolor: "darkgreen",
              },
            }}
          >
            Cambiar Estado
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ChangeOrderStatus;
