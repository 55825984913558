import axios from 'axios';
import { API_URL } from '../const/api';

const API = `${API_URL}/products-consolidated`; // Cambia la URL según tu configuración

export const createConsolidated = async (productsId) => {
  try {
    const response = await axios.post(API, { productsId }, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error en la solicitud:', error);
    throw error;
  }
};

export const getOrdersConsolidated = async () => {
  try {
    const response = await axios.get(API, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error en la solicitud:', error);
    throw error;
  }
};

export const updateConsolidated = async (updateConsolidated) => {
  try {
    const response = await axios.put(`${API}/update`, updateConsolidated, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error en la solicitud:', error);
    throw error;
  }
};