import React, { useState, useEffect } from 'react';
import { Box, Card, CardContent, Typography, Grid, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { EditAddressModal } from './EditAddressModal';
import { getCities } from '../../client/apiCities';
import { getRegions } from '../../client/apiRegions';

export const Addresses = ({ addresses }) => {
  const [open, setOpen] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [regions, setRegions] = useState([]);
  const [cities, setCities] = useState([]);
  const [addressList, setAddressList] = useState(addresses);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false); // Estado para el diálogo de éxito

  useEffect(() => {
    fetchRegions();
    fetchCities();
  }, []);

  const fetchRegions = async () => {
    const response = await getRegions();
    setRegions(response);
  };

  const fetchCities = async () => {
    const response = await getCities();
    setCities(response);
  };

  const handleEdit = (address) => {
    setSelectedAddress(address);
    setOpen(true);
  };

  const handleSave = (updatedAddress) => {
    setAddressList((prevList) =>
      prevList.map((addr) =>
        addr.id === selectedAddress.id ? { ...addr, ...updatedAddress } : addr
      )
    );
    setSuccessDialogOpen(true); // Abre el diálogo de éxito
  };

  const handleSuccessDialogClose = () => {
    setSuccessDialogOpen(false);
  };

  return (
    <Box sx={{ width: '100%', maxWidth: 800, mx: 'auto', p: 2 }}>
      <Typography variant="h4" align="center" sx={{ mb: 4 }}>
        Direcciones de Envío
      </Typography>
      <Grid container spacing={2}>
        {addressList.map((address, index) => (
          <Grid item xs={12} key={index}>
            <Card
              sx={{
                border: '1px solid #4caf50',
                boxShadow: '0 3px 5px rgba(0, 0, 0, 0.2)',
                '&:hover': {
                  boxShadow: '0 6px 10px rgba(0, 0, 0, 0.3)',
                },
              }}
            >
              <CardContent>
                <Typography variant="h6" color="textSecondary">
                  Dirección: {address.address}
                </Typography>
                <Typography variant="body1">Número: {address.number}</Typography>
                <Typography variant="body1">Ciudad: {address.city}</Typography>
                <Typography variant="body1">Región: {address.region}</Typography>
                <Typography variant="body1">Entrega en: {address.department}</Typography>
                <Typography variant="body1">Información adicional: {address.additionalInfo}</Typography>
                <Button variant="outlined" color="primary" sx={{ mt: 2 }} onClick={() => handleEdit(address)}>
                  Editar
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      {selectedAddress && (
        <EditAddressModal
          open={open}
          handleClose={() => setOpen(false)}
          address={selectedAddress}
          cities={cities}
          regions={regions}
          onSave={handleSave}
        />
      )}

      {/* Diálogo de éxito */}
      <Dialog
        open={successDialogOpen}
        onClose={handleSuccessDialogClose}
      >
        <DialogTitle>Actualización exitosa</DialogTitle>
        <DialogContent>
          <DialogContentText>
            La dirección se ha actualizado con éxito.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSuccessDialogClose} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
