import React, { useState } from "react";
import { Box, Button, Container, Typography } from "@mui/material";
import ChangeOrderStatus from "./ChangeOrderStatus";
import UpdateMembershipStatus from "./UpdateMembershipStatus";
import UploadPriceFile from "./UploadPriceFile";
import { ConsolidatedOrders } from "./ConsolidatedOrdersPage"; // Importar el nuevo componente

export const AdminPage = () => {
  const [activeTab, setActiveTab] = useState("orderStatus");

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <Container>
      <Typography
        variant="h4"
        sx={{
          mb: 4,
          fontWeight: "bold",
          textAlign: "center",
          color: "green",
        }}
      >
        Admin Panel
      </Typography>
      
      {/* Botones de Pestaña */}
      <Box sx={{ display: "flex", justifyContent: "center", mb: 3, flexWrap: "wrap", gap: 2 }}>
        <Button
          variant={activeTab === "orderStatus" ? "contained" : "outlined"}
          onClick={() => handleTabChange("orderStatus")}
          sx={{ mx: 1 }}
        >
          Cambiar Estado de Pedido
        </Button>
        <Button
          variant={activeTab === "membershipStatus" ? "contained" : "outlined"}
          onClick={() => handleTabChange("membershipStatus")}
          sx={{ mx: 1 }}
        >
          Actualizar Estado de Membresía
        </Button>
        <Button
          variant={activeTab === "priceChange" ? "contained" : "outlined"}
          onClick={() => handleTabChange("priceChange")}
          sx={{ mx: 1 }}
        >
          Cambio de Precio
        </Button>
        <Button
          variant={activeTab === "consolidatedOrders" ? "contained" : "outlined"}
          onClick={() => handleTabChange("consolidatedOrders")}
          sx={{ mx: 1 }}
        >
          Órdenes Consolidadas
        </Button>
      </Box>

      {/* Mostrar el contenido de la pestaña seleccionada */}
      {activeTab === "orderStatus" && <ChangeOrderStatus />}
      {activeTab === "membershipStatus" && <UpdateMembershipStatus />}
      {activeTab === "priceChange" && <UploadPriceFile />}
      {activeTab === "consolidatedOrders" && <ConsolidatedOrders />}
    </Container>
  );
};
