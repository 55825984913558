import axios from 'axios';
import { API_URL } from '../const/api';

const API = `${API_URL}/coupons`; // Cambia la URL según tu configuración
export const getCoupon = async (coupon) => {
  try {
    const response = await axios.post(API, coupon, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error en la solicitud:', error);
    throw error;
  }
};