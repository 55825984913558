import React, { useContext, useState, useEffect } from "react";
import { CartContext } from "../../context/CartContext";
import { AuthContext } from "../../context/AuthContext";
import {
  Button,
  CardMedia,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  IconButton,
  Divider,
  Box,
  useMediaQuery,
} from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { styled } from "@mui/material/styles";
import "./CartPage.scss";
import Auth from "../Auth/Auth";
import { useNavigate } from "react-router-dom";
import { createTransactionSales } from "../../client/apiWebpayService";
import { getCities } from "../../client/apiCities";
import { getClientById } from "../../client/apiClients";
import PersonalData from "../CartPage/PersonalData";
import EmptyCart from "../CartPage/EmptyCart";
import { CartDialogs } from "./CartDialogs";
import OrderSummary from "./OrderSummary";

const ProductImage = styled(CardMedia)({
  width: 100,
  height: 100,
  objectFit: "cover",
  marginRight: 16,
});

const StyledTable = styled(Table)({
  minWidth: 650,
});

const BoldTableCell = styled(TableCell)({
  fontWeight: "bold",
});

export const CartPage = () => {
  const { 
    cartItems,
    removeFromCart,
    totalPrice,
    updateQuantity,
    addToCart,
    discount, coupon, balanceFavor,
    finalPriceSummary,
    setBalanceFavor, difference, shippingAmount = 2900, setShippingAmount 
    } = useContext(CartContext);
  const { user, login, userId, balance, setBalance } = useContext(AuthContext);
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [hasShownDialogOnce, setHasShownDialogOnce] = useState(false);
  
  const [paymentMethod, setPaymentMethod] = useState("transfer");
  const navigate = useNavigate();
  const [showPersonalData, setShowPersonalData] = useState(false);
  const [clientData, setClientData] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState("");
  const [newAddress, setNewAddress] = useState("");
  const [selectedComuna, setSelectedComuna] = useState("");
  const [comunas, setComunas] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [isNewAddressEnabled, setIsNewAddressEnabled] = useState(false); 
  const [deliveryDate, setDeliveryDate] = useState("");
  const [deliveryTime, setDeliveryTime] = useState('AM');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [openInsufficientBalanceDialog, setOpenInsufficientBalanceDialog] = useState(false);
  const isMobile = useMediaQuery("(max-width:600px)");
  
  const [validationDialogOpen, setValidationDialogOpen] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");

  useEffect(() => {
    if (user) {
      setOpenLoginModal(false);
      fetchClientData();
    }

    const savedCartData = JSON.parse(localStorage.getItem('cartData'));
    if (savedCartData) {
      setSelectedAddress(savedCartData.deliveryDetails.address);
      setNewAddress(savedCartData.deliveryDetails.isNew ? savedCartData.deliveryDetails.address : "");
      setSelectedComuna(savedCartData.deliveryDetails.comuna || "");
      setDeliveryDate(savedCartData.deliveryDetails.deliveryDate);
      setDeliveryTime(savedCartData.deliveryDetails.deliveryTime);
      //setShippingAmount(savedCartData.deliveryDetails.shippingAmount || 0);
    }

    fetchComunas();
  }, [user]);

  const fetchComunas = async () => {
    try {
      const cities = await getCities();
      setComunas(cities);
    } catch (error) {
      console.error("Error al obtener las comunas:", error);
    }
  };

  const fetchClientData = async () => {
    setLoading(true);
    try {
      const data = await getClientById(userId);
      setClientData(data);
      console.log(data)
      setBalanceFavor(data.balance);
      setAddresses(data.destinations);
    } catch (error) {
      console.error("Error al obtener datos del cliente:", error);
      setError(error);
    }
    setLoading(false);
  };

  const calculateMembershipSavings = () => {
    return cartItems
      .filter(item => item.isMembership)
      .reduce((total, item) => {
        const normalPrice = item.price_normal || item.price;
        const membershipPrice = item.price_membership;
        const savings = (normalPrice - membershipPrice) * item.quantity;
        return total + savings;
      }, 0);
  };

  const confirmPurchase = async () => {
    if (paymentMethod === "transfer") {
      navigate("/transfer-payment");
    } else {
      try {
        const amount = totalPrice() + shippingAmount;
        const response = await createTransactionSales(amount);
        if (response.url && response.token) {
          window.location.href = `${response.url}?token_ws=${response.token}`;
        }
      } catch (error) {
        console.error("Error al iniciar la transacción:", error);
      }
    }
  };

  const formatNumber = (number) => number.toLocaleString("es-CL");

  const handlePaymentMethodChange = (event) => {
    setPaymentMethod(event.target.value);
  };

  const handleIncrease = (product) => {
    const isNormalPriceProduct = product.id.includes("_normal");
    const productNormalId = isNormalPriceProduct ? product.id : `${product.id}_normal`;

    const productInCartNormal = cartItems.find((item) => item.id === productNormalId && !item.isMembership);
    const productInCartMembership = cartItems.find((item) => item.id === product.id && item.isMembership);

    if (product.isMembership && balance >= product.price_membership) {
      if (productInCartMembership) {
        updateQuantity(product.id, productInCartMembership.quantity + 1);
      } else {
        addToCart({ ...product, isMembership: true, quantity: 1 });
      }
      setBalance((prevBalance) => prevBalance - product.price_membership);
    } else {
      if (productInCartNormal) {
        updateQuantity(productNormalId, productInCartNormal.quantity + 1);
      } else {
        addToCart({ 
          ...product, 
          id: productNormalId, 
          isMembership: false, 
          price: product.price_normal || product.price,
          quantity: 1 
        });
      }
    }

    if (!hasShownDialogOnce && balance < product.price_membership) {
      setOpenInsufficientBalanceDialog(true);
      setHasShownDialogOnce(true);
    }
  };

  const removeProductAndUpdateBalance = (product) => {
    const productInCart = cartItems.find((item) => item.id === product.id && item.isMembership);

    if (productInCart) {
      const totalRefund = productInCart.quantity * parseFloat(product.price_membership);
      setBalance((prevBalance) => prevBalance + totalRefund);
    }

    removeFromCart(product.id);
  };

  const handleDecrease = (product) => {
    const isNormalPriceProduct = product.id.includes("_normal");
    const productNormalId = isNormalPriceProduct ? product.id : `${product.id}_normal`;

    const productInCartNormal = cartItems.find((item) => item.id === productNormalId && !item.isMembership);
    const productInCartMembership = cartItems.find((item) => item.id === product.id && item.isMembership);

    if (productInCartMembership) {
      if (productInCartMembership.quantity > 1) {
        updateQuantity(product.id, productInCartMembership.quantity - 1);
        setBalance((prevBalance) => prevBalance + product.price_membership);
      } else {
        removeProductAndUpdateBalance(product); 
      }
    } else if (productInCartNormal) {
      if (productInCartNormal.quantity > 1) {
        updateQuantity(productNormalId, productInCartNormal.quantity - 1);
      } else {
        removeFromCart(productNormalId);
      }
    }
  };

  const handleDialogClose = () => {
    setOpenInsufficientBalanceDialog(false);
    setValidationDialogOpen(false);
  };

  const handleBack = () => {
    if (showPersonalData) {
      setShowPersonalData(false);
    } else {
      navigate('/');
    }
  };

  const prepareForCheckout = () => {
    if (!user) {
      setOpenLoginModal(true);
      return;
    }
    if (showPersonalData) {
      validateAndCheckout();
    } else {
      setShowPersonalData(true);
    }
  };

  const validateAndCheckout = () => {
    if (!selectedAddress && !newAddress.trim()) {
      setValidationMessage("Por favor, seleccione una dirección existente o ingrese una nueva dirección.");
      setValidationDialogOpen(true);
      return;
    }

    if (!selectedComuna) {
      setValidationMessage("Por favor, seleccione una comuna.");
      setValidationDialogOpen(true);
      return;
    }

    if (!deliveryDate) {
      setValidationMessage("Por favor, seleccione una fecha de entrega disponible.");
      setValidationDialogOpen(true);
      return;
    }

    /*if (shippingAmount<=0) {
      setValidationMessage("Por favor, seleccione nuevamente su comuna.");
      setValidationDialogOpen(true);
      return;
    }*/

    confirmPurchase();
    saveCartDataToLocalStorage();
  };

  const saveCartDataToLocalStorage = () => {
    const isNewAddress = !selectedAddress && newAddress.trim();
    const hasMembershipItem = cartItems.some(item => item.isMembership === true);
  
    const membershipSavings = calculateMembershipSavings();
  
    const cartData = {
      items: cartItems,
      discount:discount,
      coupon: coupon,
      totalPrice: totalPrice(),
      difference:difference,
      deliveryDetails: {
        address: selectedAddress || newAddress,
        city: selectedComuna,
        isNew: isNewAddress,
        hasMembershipItem: hasMembershipItem,
        deliveryDate,
        deliveryTime,
        shippingAmount,
      },
      balance: balance,
      paymentMethod,
      userId: userId,
      membershipSavings: membershipSavings,
      balanceFavor,
      finalPriceSummary,
    };
  
    localStorage.setItem('cartData', JSON.stringify(cartData));
  };
  

  return (
    <section className="cart-page" style={{ display: "flex", flexDirection: "column" }}>
      <CartDialogs
        validationDialogOpen={validationDialogOpen}
        onValidationDialogClose={handleDialogClose}
        validationMessage={validationMessage}
        openInsufficientBalanceDialog={openInsufficientBalanceDialog}
        onInsufficientBalanceDialogClose={handleDialogClose}
      />

      <Box mb={2} p={2}>
        <Typography variant="h6" gutterBottom>
          Mi carro
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Si alguno de los productos no estuviera disponible al preparar tu pedido, te llamaremos para que nos indiques cómo te gustaría sustituirlo.
        </Typography>
        <Divider sx={{ margin: "10px 0" }} />
      </Box>
      {cartItems.length === 0 ? (
        <EmptyCart />
      ) : (
      <Box display="flex" flexDirection={isMobile ? "column" : "row"} gap={2}>
        <Box style={{ flex: 3 }}>
        {isMobile || showPersonalData ? (
          <PersonalData 
            clientData={clientData}
            addresses={addresses}
            selectedAddress={selectedAddress}
            isNewAddressEnabled={isNewAddressEnabled}
            newAddress={newAddress}
            selectedComuna={selectedComuna}
            comunas={comunas}
            deliveryDate={deliveryDate}
            deliveryTime={deliveryTime}
            setNewAddress={setNewAddress}
            setSelectedComuna={setSelectedComuna}
            setSelectedAddress={setSelectedAddress}
            setIsNewAddressEnabled={setIsNewAddressEnabled}
            setDeliveryDate={setDeliveryDate}
            setDeliveryTime={setDeliveryTime}
            paymentMethod={paymentMethod}
            handlePaymentMethodChange={handlePaymentMethodChange}
            setShippingAmount={setShippingAmount}
            fetchClientData={fetchClientData}
          />
        ) : (
          <TableContainer component={Paper}>
            <StyledTable>
              <TableHead>
                <TableRow>
                  <BoldTableCell>Producto</BoldTableCell>
                  <BoldTableCell>Precio</BoldTableCell>
                  <BoldTableCell>Cantidad</BoldTableCell>
                  <BoldTableCell>Subtotal</BoldTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cartItems.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <ProductImage component="img" image={item.image} alt={item.name} />
                        <div>
                          <Typography variant="caption" color="textSecondary">
                            {item.brand}
                          </Typography>
                          <Typography variant="body2" fontWeight="bold">
                            {item.name}
                          </Typography>
                          <Button variant="text" color="error" onClick={() => removeProductAndUpdateBalance(item)}>
                            Eliminar
                          </Button>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      ${formatNumber(item.price)}
                      <Typography variant="body2" sx={{ color: item.isMembership ? "green" : "red", fontSize: "0.8rem" }}>
                        {item.isMembership ? "Precio de membresía" : "Precio normal"}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <IconButton onClick={() => handleDecrease(item)} size="small">
                          {item.quantity === 1 ? <DeleteIcon /> : <RemoveIcon />}
                        </IconButton>
                        <TextField
                          value={item.quantity}
                          variant="outlined"
                          size="small"
                          sx={{ width: "50px", textAlign: "center" }}
                          inputProps={{ readOnly: true }}
                        />
                        <IconButton onClick={() => handleIncrease(item)} size="small">
                          <AddIcon />
                        </IconButton>
                      </div>
                    </TableCell>
                    <TableCell>${formatNumber(item.price * item.quantity)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </StyledTable>
          </TableContainer>
        )}
        </Box>
        <OrderSummary
            isMobile={isMobile}
            totalPrice={totalPrice}
            shippingAmount={shippingAmount}
            calculateMembershipSavings={calculateMembershipSavings}
            formatNumber={formatNumber}
            handleBack={handleBack}
            prepareForCheckout={prepareForCheckout}
            showPersonalData={showPersonalData}
            clientData={clientData}
          />
      </Box>
      )}
      <Auth open={openLoginModal} onClose={() => setOpenLoginModal(false)} onSuccess={login} />
    </section>
  );
};

export default CartPage;
