import React, { useState, useContext, useEffect, useRef } from "react";
import {
  IconButton,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Button,
  Box,
  useMediaQuery,
  useTheme,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { CartContext } from "../../context/CartContext";
import { AuthContext } from "../../context/AuthContext";
import { getPrincipal } from "../../client/apiProducts";
import { Link } from "react-router-dom";
import { InsufficientBalanceDialog } from "./InsufficientBalanceDialog";

export const TopSwiper = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { addToCart, removeFromCart, updateQuantity, cartItems, setCartItems } = useContext(CartContext);
  const { balance, user, setBalance } = useContext(AuthContext);
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [openMembershipModal, setOpenMembershipModal] = useState(false);
  const [hasShownDialogOnce, setHasShownDialogOnce] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOrder, setSortOrder] = useState(""); // Estado para el orden
  const productsPerPage = 20;
  const [openCampoDialog, setOpenCampoDialog] = useState(false);
  const [hasAddedCampoProduct, setHasAddedCampoProduct] = useState(false);
  
  const productsContainerRef = useRef(null);

  const subGroupOrder = [
    "Frutas y Verduras",
    "Frutos secos, Cereales y Granos",
    "Huevos y Otras Proteinas",
    "Legumbres y otros productos de Despensa",
  ];

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await getPrincipal();
        const fetchedProducts = response.map((item) => ({
          id: item.id,
          name: item.name || "Producto Desconocido",
          price: parseInt(item.price),
          price_membership: parseInt(item.price_membership),
          image: item.url_img,
          brand: item.brand,
          category: item.category,
          type: item.type,
          subGroup: item.subGroup,
          sku: item.sku,
        }));
        setProducts(fetchedProducts);
        setFilteredProducts(fetchedProducts);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };
    fetchProducts();
  }, []);

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);

  const sortedCurrentProducts = [...currentProducts].sort((a, b) => {
    if (sortOrder === "asc") return a.name.localeCompare(b.name);
    if (sortOrder === "desc") return b.name.localeCompare(a.name);
    return 0;
  });

  const handleAddToCart = (product, isMembership = true) => {
    const productPrice = product.price;
    const membershipPrice = product.price_membership;

    if (product.type !== "Convencional" && !hasAddedCampoProduct) {
      setOpenCampoDialog(true);
      setHasAddedCampoProduct(true);
    }

    if (isMembership && balance < membershipPrice) {
      if (!hasShownDialogOnce) {
        setSelectedProduct(product);
        setOpenDialog(true);
        setHasShownDialogOnce(true);
        return;
      } else {
        isMembership = false;
      }
    }

    const priceToUse = isMembership ? membershipPrice : productPrice;
    const productToAdd = {
      ...product,
      price: priceToUse,
      price_membership: membershipPrice,
      price_normal: productPrice,
      isMembership: isMembership,
      brand: product.brand,
      id: isMembership ? String(product.id) : String(product.id) + "_normal",
      type: product.type
    };

    addToCart(productToAdd, 1);

    if (isMembership) {
      setBalance((prevBalance) => prevBalance - membershipPrice);
    }
  };

  const handleIncrease = (product) => {
    const membershipPrice = product.price_membership;
    const productMembershipId = String(product.id);
    const productNormalId = String(product.id) + "_normal";

    const productInCart = cartItems.find((item) => item.id === productMembershipId && item.isMembership);
    const normalProductInCart = cartItems.find((item) => item.id === productNormalId && !item.isMembership);

    if (balance >= membershipPrice) {
      if (productInCart) {
        updateQuantity(productMembershipId, productInCart.quantity + 1);
      } else {
        handleAddToCart(product, true);
      }
      setBalance((prevBalance) => prevBalance - membershipPrice);
    } else {
      if (!hasShownDialogOnce) {
        setSelectedProduct(product);
        setOpenDialog(true);
        setHasShownDialogOnce(true);
      } else {
        if (normalProductInCart) {
          updateQuantity(productNormalId, normalProductInCart.quantity + 1);
        } else {
          handleAddToCart(product, false);
        }
      }
    }
  };

  const handleDecrease = (product) => {
    const membershipProductId = String(product.id);
    const normalProductId = String(product.id) + "_normal";

    let productInCart = cartItems.find((item) => item.id === normalProductId && !item.isMembership);

    if (!productInCart) {
      productInCart = cartItems.find((item) => item.id === membershipProductId && item.isMembership);
    }

    if (productInCart) {
      if (productInCart.quantity > 1) {
        updateQuantity(productInCart.id, productInCart.quantity - 1);
        if (productInCart.isMembership) {
          setBalance((prevBalance) => prevBalance + product.price_membership);
        }
      } else {
        removeFromCart(productInCart.id);
        if (productInCart.isMembership) {
          setBalance((prevBalance) => prevBalance + product.price_membership);
        }
      }
    }
  };

  const getProductQuantity = (productId) => {
    const productNormal = cartItems.find((item) => item.id === String(productId) + "_normal");
    const productMembership = cartItems.find((item) => item.id === String(productId) && item.isMembership);
    return {
      normal: productNormal ? productNormal.quantity : 0,
      membership: productMembership ? productMembership.quantity : 0,
      total: (productNormal ? productNormal.quantity : 0) + (productMembership ? productMembership.quantity : 0),
    };
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setSelectedProduct(null);
  };

  const handleAddAtNormalPrice = () => {
    if (selectedProduct) {
      handleAddToCart(selectedProduct, false);
      handleDialogClose();
    }
  };

  const handleOpenMembershipModal = () => {
    setOpenMembershipModal(true);
    handleDialogClose();
  };

  const groupedProducts = subGroupOrder.reduce((acc, subGroup) => {
    acc[subGroup] = sortedCurrentProducts.filter((product) => product.subGroup === subGroup);
    return acc;
  }, {});

  return (
    <section className="section section--products-top" ref={productsContainerRef}>
      <Box width="80%" margin="auto">
        {subGroupOrder.map((subGroup) => (
          <React.Fragment key={subGroup}>
            {groupedProducts[subGroup].length > 0 && (
              <>
                <Box display="flex" alignItems="center" mt={4} mb={2}>
                  <Typography variant="h5" fontWeight="bold" color="primary">
                    {subGroup}
                  </Typography>
                  <Button component={Link} to={`/filtered-products/${subGroup}`} color="secondary" sx={{ ml: 2 }}>
                    Ver todo
                  </Button>
                </Box>
                (*) Imágenes Referenciales. 
                <Divider sx={{ mb: 2 }} />

                <Box
                  display="grid"
                  gridTemplateColumns={{
                    xs: "repeat(2, 1fr)",
                    sm: "repeat(5, 1fr)",
                  }}
                  gap={2}
                >
                  {groupedProducts[subGroup].map((product) => {
                    const { normal: quantityNormal, membership: quantityMembership, total: totalQuantity } =
                      getProductQuantity(product.id);

                    return (
                      <Card key={product.id} className="products-top__card" sx={{ maxWidth: 200 }}>
                        <Box position="relative">
                          <CardMedia
                            component="img"
                            height="133"
                            image={product.image}
                            alt={product.name}
                            sx={{ objectFit: "cover" }}
                          />
                          {product.type !== "Convencional" && (
                            <Box
                              sx={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                backgroundColor: "green",
                                color: "white",
                                fontWeight: "bold",
                                padding: "4px 8px",
                                borderRadius: "4px",
                              }}
                            >
                              {product.type}
                            </Box>
                          )}
                        </Box>

                        <CardContent>
                          <Typography variant="body2" fontSize="0.75rem" color="textPrimary" fontWeight="bold" mt={1}>
                            {product.name}
                          </Typography>
                          <Typography variant="body1" fontSize="1.1rem" color="green" fontWeight="bold" mt={1}>
                            ${product.price_membership.toLocaleString("es-CL")}{" "}
                            <span style={{ fontSize: "0.8rem", color: "gray" }}>Membresía</span>
                          </Typography>
                          <Typography variant="body1" fontSize="1rem" color="textPrimary" mt={1}>
                            ${product.price.toLocaleString("es-CL")}{" "}
                            <Typography component="span" fontSize="0.8rem" color="textSecondary">
                              precio normal
                            </Typography>
                          </Typography>

                          {quantityMembership > 0 && (
                            <Typography variant="body2" color="green">
                              Membresía: {quantityMembership}
                            </Typography>
                          )}
                          {quantityNormal > 0 && (
                            <Typography variant="body2" color="red">
                              Normal: {quantityNormal}
                            </Typography>
                          )}

                          {totalQuantity === 0 ? (
                            <Button
                              onClick={() => handleAddToCart(product)}
                              variant="contained"
                              color="success"
                              fullWidth
                              sx={{ mt: 1 }}
                            >
                              Agregar
                            </Button>
                          ) : (
                            <div
                              className="quantity-selector"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                marginTop: "10px",
                              }}
                            >
                              <IconButton
                                onClick={() => handleDecrease(product)}
                                sx={{
                                  color: "white",
                                  backgroundColor: "green",
                                  "&:hover": {
                                    backgroundColor: "#66bb6a",
                                  },
                                }}
                              >
                                <RemoveIcon />
                              </IconButton>
                              <Typography
                                sx={{ width: "50px", textAlign: "center", backgroundColor: "#f5f5f5" }}
                              >
                                {totalQuantity}
                              </Typography>
                              <IconButton
                                onClick={() => handleIncrease(product)}
                                sx={{
                                  color: "white",
                                  backgroundColor: "green",
                                  "&:hover": {
                                    backgroundColor: "#66bb6a",
                                  },
                                }}
                              >
                                <AddIcon />
                              </IconButton>
                            </div>
                          )}
                        </CardContent>
                      </Card>
                    );
                  })}
                </Box>
              </>
            )}
          </React.Fragment>
        ))}
      </Box>

      <InsufficientBalanceDialog
        open={openDialog}
        onClose={handleDialogClose}
        onAddAtNormalPrice={handleAddAtNormalPrice}
        onOpenMembershipModal={handleOpenMembershipModal}
      />
      <Dialog
        open={openCampoDialog}
        onClose={() => setOpenCampoDialog(false)}
      >
        <DialogTitle>Información de envío</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Los productos con "Etiqueta Verde" solo tienen envío disponible el próximo día sábado.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenCampoDialog(false)} color="primary">
            Entendido
          </Button>
        </DialogActions>
      </Dialog>
    </section>
  );
};
