import React, { useState, useContext, useEffect, useRef } from "react";
import {
  IconButton,
  Typography,
  Button,
  Box,
  useMediaQuery,
  useTheme,
  Pagination,
  Checkbox,
  FormControlLabel,
  Divider,
  Collapse,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { CartContext } from "../../context/CartContext";
import { AuthContext } from "../../context/AuthContext";
import { getProducts, getProductsSubgroup } from "../../client/apiProducts";
import InsufficientBalanceDialog from "./InsufficientBalanceDialog";
import FilterDrawer from "./FilterDrawer";
import ProductCard from "./ProductCard";
import SubgroupButtons from "./SubgroupButtons";

export const FilteredProductsPage = () => {
  const { subGroupName, searchTerm } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { addToCart, removeFromCart, updateQuantity, cartItems } = useContext(CartContext);
  const { balance, setBalance } = useContext(AuthContext);
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [selectedSubcategories, setSelectedSubcategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [allSubGroups, setAllSubGroups] = useState([]);
  const [expandedGroups, setExpandedGroups] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [hasShownDialogOnce, setHasShownDialogOnce] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [productsPerPage, setProductsPerPage] = useState(16); // Estado para los productos por página
  const navigate = useNavigate();
  const location = useLocation();
  const [openCampoDialog, setOpenCampoDialog] = useState(false);
  const [hasAddedCampoProduct, setHasAddedCampoProduct] = useState(false);
  const productsContainerRef = useRef(null);
  const [sortType, setSortType] = useState("none");
  // Manejador para cambiar el número de productos por página
  const handleProductsPerPageChange = (event) => {
    setProductsPerPage(event.target.value);
    setCurrentPage(1); // Resetear a la primera página cuando se cambie el número de productos
  };

  const sortProducts = (productsList) => {
    if (sortType === "asc") {
      return productsList.sort((a, b) => a.name.localeCompare(b.name));
    } else if (sortType === "desc") {
      return productsList.sort((a, b) => b.name.localeCompare(a.name));
    }
    return productsList;
  };


  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await getProducts();
        const fetchedProducts = response.map((item) => ({
          id: item.id,
          name: item.name || "Producto Desconocido",
          price: parseInt(item.price),
          price_membership: parseInt(item.price_membership),
          image: item.url_img,
          brand: item.brand,
          category: item.category,
          subGroup: item.subGroup,
          subCategory: item.subCategory,
          type: item.type,
        }));
        setProducts(sortProducts(fetchedProducts));
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };
    fetchProducts();
  }, []);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categories = await getProductsSubgroup();
        setAllSubGroups(categories);

        const currentCategory = categories.find((category) =>
          Object.keys(category).includes(subGroupName)
        );

        if (currentCategory) {
          setSubcategories(currentCategory[subGroupName]);
        }
      } catch (error) {
        console.error("Error fetching subcategories:", error);
      }
    };
    fetchCategories();
  }, [subGroupName]);

    useEffect(() => {
    // Filtrar productos por categorías seleccionadas
    if (selectedCategories.length > 0) {
      const filtered = products.filter((product) =>
        selectedCategories.includes(product.category) // Filtrar por categoría
      );
      setFilteredProducts(filtered);
    } else {
      setFilteredProducts(products); // Si no hay filtros, mostrar todos los productos
    }
  }, [selectedCategories, products]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const searchTerm = searchParams.get("searchTerm");
    if (subGroupName) {
      // Filtrar productos por subgrupo y subcategorías seleccionadas
      const filtered = products.filter((product) =>
        product.subGroup === subGroupName &&
        (selectedSubcategories.length > 0 ? selectedSubcategories.includes(product.subCategory) : true)
      );
      setFilteredProducts(filtered);
    } else if (searchTerm) {
      // Filtrar productos por searchTerm
      const filtered = products.filter((product) =>
        product.subCategory.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredProducts(filtered);
    } else {
      setFilteredProducts(products); // Si no hay filtro, mostrar todos los productos
    }
  }, [products, subGroupName, location.search, selectedSubcategories]);



  const handleCategoryChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedCategories((prev) => [...prev, value]); // Agregar categoría seleccionada
      
    } else {
      setSelectedCategories((prev) => prev.filter((category) => category !== value)); // Eliminar categoría si se deselecciona
    }
  };

  const handleGroupToggle = (groupName) => {
    setExpandedGroups((prev) => ({
      ...prev,
      [groupName]: !prev[groupName],
    }));
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    // Desplazar hacia la parte superior del contenedor de productos
    const offsetTop = productsContainerRef.current?.offsetTop || 0;
    const additionalOffset = -100; // Ajusta este valor según lo necesario
    window.scrollTo({
      top: offsetTop + additionalOffset,
      behavior: "smooth",
    });
  };

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleSubGroupChange = (newSubGroup) => {
    if (newSubGroup !== subGroupName) {
      navigate(`/filtered-products/${newSubGroup}`);
    }
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  
  const currentProducts = sortProducts([...filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct)]);

  const handleAddToCart = (product, isMembership = true) => {
    const productPrice = product.price;
    const membershipPrice = product.price_membership;

    if (product.type !== "Convencional" && !hasAddedCampoProduct) {
      setOpenCampoDialog(true);
      setHasAddedCampoProduct(true);
    }

    if (isMembership && balance < membershipPrice) {
      if (!hasShownDialogOnce) {
        setSelectedProduct(product);
        setOpenDialog(true);
        setHasShownDialogOnce(true);
        return;
      } else {
        isMembership = false;
      }
    }

    const priceToUse = isMembership ? membershipPrice : productPrice;

    const productToAdd = {
      ...product,
      price: priceToUse,
      price_membership: membershipPrice,
      price_normal: productPrice,
      isMembership: isMembership,
      brand: product.brand,
      id: isMembership ? String(product.id) : String(product.id) + "_normal",
      type: product.type
    };

    addToCart(productToAdd, 1);

    if (isMembership) {
      setBalance((prevBalance) => prevBalance - membershipPrice);
    }
  };

  const handleIncrease = (product) => {
    const membershipPrice = product.price_membership;
    //const productPrice = product.price;

    const productMembershipId = String(product.id);
    const productNormalId = String(product.id) + "_normal";

    const productInCart = cartItems.find((item) => item.id === productMembershipId && item.isMembership);
    const normalProductInCart = cartItems.find((item) => item.id === productNormalId && !item.isMembership);

    if (balance >= membershipPrice) {
      if (productInCart) {
        updateQuantity(productMembershipId, productInCart.quantity + 1);
      } else {
        handleAddToCart(product, true);
      }
      setBalance((prevBalance) => prevBalance - membershipPrice);
    } else {
      if (!hasShownDialogOnce) {
        setSelectedProduct(product);
        setOpenDialog(true);
        setHasShownDialogOnce(true);
      } else {
        if (normalProductInCart) {
          updateQuantity(productNormalId, normalProductInCart.quantity + 1);
        } else {
          handleAddToCart(product, false);
        }
      }
    }
  };

  const handleDecrease = (product) => {
    const membershipProductId = String(product.id);
    const normalProductId = String(product.id) + "_normal";

    let productInCart = cartItems.find((item) => item.id === normalProductId && !item.isMembership);

    if (!productInCart) {
      productInCart = cartItems.find((item) => item.id === membershipProductId && item.isMembership);
    }

    if (productInCart) {
      if (productInCart.quantity > 1) {
        updateQuantity(productInCart.id, productInCart.quantity - 1);
        if (productInCart.isMembership) {
          setBalance((prevBalance) => prevBalance + product.price_membership);
        }
      } else {
        removeFromCart(productInCart.id);
        if (productInCart.isMembership) {
          setBalance((prevBalance) => prevBalance + product.price_membership);
        }
      }
    }
  };

  const getProductQuantity = (productId) => {
    const productNormal = cartItems.find((item) => item.id === String(productId) + "_normal");
    const productMembership = cartItems.find((item) => item.id === String(productId) && item.isMembership);
    return {
      normal: productNormal ? productNormal.quantity : 0,
      membership: productMembership ? productMembership.quantity : 0,
      total: (productNormal ? productNormal.quantity : 0) + (productMembership ? productMembership.quantity : 0),
    };
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setSelectedProduct(null);
  };

  const handleAddAtNormalPrice = () => {
    if (selectedProduct) {
      handleAddToCart(selectedProduct, false);
      handleDialogClose();
    }
  };

  return (
    <section className="section section--products-top" ref={productsContainerRef}>

      {!isMobile && (
        <SubgroupButtons
        allSubGroups={allSubGroups}
        subGroupName={subGroupName}
        handleSubGroupChange={handleSubGroupChange}
        selectedCategories={selectedCategories}
      />
      )}

      {isMobile && (
       <Box mt={2} width="100%" >
       <Button
      variant="outlined"
      sx={{
        backgroundColor: 'white',
        borderColor: 'green',
        color: 'green',
        minWidth: '100px', // Asegura que ambos botones tengan el mismo ancho
        ml: 4, // Agrega un margen a la izquierda para mover el botón a la derecha
      }}
      startIcon={<FilterListIcon />}
      onClick={handleDrawerToggle}
    >
         Filtrar
       </Button>
     </Box>

      )}
      <FilterDrawer
              isDrawerOpen={isDrawerOpen}
              handleDrawerToggle={handleDrawerToggle}
              allSubGroups={allSubGroups}
              expandedGroups={expandedGroups}
              handleGroupToggle={handleGroupToggle}
              selectedCategories={selectedCategories}
              handleCategoryChange={handleCategoryChange}
            />

      
      <Box display="flex" justifyContent="center" mt={4} width={isMobile ? "80%" : "70%"} margin="auto">
        {!isMobile && (
          <Box width="20%" display="flex" flexDirection="column">
            <FormControl sx={{ minWidth: 150, width: 180 }}>
              <InputLabel id="products-per-page-label">Productos por página</InputLabel>
              <Select
                labelId="products-per-page-label"
                id="products-per-page-select"
                value={productsPerPage}
                label="Productos por página"
                onChange={handleProductsPerPageChange}
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "green", // Contorno verde por defecto
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "green", // Contorno verde al pasar el mouse
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "green", // Contorno verde cuando está enfocado
                  },
                }}
              >
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={150}>150</MenuItem>
              </Select>
            </FormControl>
            <Divider sx={{ mt: 5, width: '90%', margin: '0 1 auto' }} />
            <Typography variant="h6">Filtros</Typography>
            {allSubGroups.map((subGroup, index) => {
              const groupName = Object.keys(subGroup)[0];
              const categories = subGroup[groupName];
              const isExpanded = expandedGroups[groupName] || false;

              return (
                <Box key={index} mb={1}>
                  <Box display="flex" alignItems="center" onClick={() => handleGroupToggle(groupName)} sx={{ cursor: "pointer" }}>
                    <IconButton size="small" sx={{ color: "green" }}>
                      <ExpandMoreIcon style={{ transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)" }} />
                    </IconButton>
                    <Typography variant="subtitle1" sx={{ ml: 1 }}>{groupName}</Typography>
                  </Box>
                  <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                    <Box ml={4}>
                      {categories.map((category, idx) => (
                        <FormControlLabel
                          key={idx}
                          control={
                            <Checkbox
                              value={category}
                              onChange={handleCategoryChange}
                              checked={selectedCategories.includes(category)}
                            />
                          }
                          label={category}
                        />
                      ))}
                    </Box>
                  </Collapse>
                </Box>
              );
            })}
          </Box>
        )}

        <Box
          width={isMobile ? "100%" : "75%"}
          display="grid"
          gridTemplateColumns={isMobile ? "repeat(2, 1fr)" : "repeat(4, 1fr)"}
          gap={2}
          mt={2}
        >
          {currentProducts.map((product) => {
            const { normal: quantityNormal, membership: quantityMembership, total: totalQuantity } = getProductQuantity(product.id);

            return (
              
              <ProductCard
                key={product.id}
                product={product}
                quantityNormal={quantityNormal}
                quantityMembership={quantityMembership}
                totalQuantity={totalQuantity}
                handleAddToCart={handleAddToCart}
                handleIncrease={handleIncrease}
                handleDecrease={handleDecrease}
              />
            );
          })}
        </Box>
        
      </Box>
      <Box display="flex" justifyContent="center" mt={4}><span>(*) Imágenes Referenciales.</span> </Box>
      <Box display="flex" justifyContent="center" mt={4}>
      
        <Pagination count={Math.ceil(filteredProducts.length / productsPerPage)} page={currentPage} onChange={handlePageChange} color="primary" />
      </Box>

      <InsufficientBalanceDialog
        open={openDialog}
        onClose={handleDialogClose}
        onAddAtNormalPrice={handleAddAtNormalPrice}
        isMobile={isMobile}
      />
      <Dialog
        open={openCampoDialog}
        onClose={() => setOpenCampoDialog(false)}
      >
        <DialogTitle>Información de envío</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Los productos con "Etiqueta Verde" solo tienen envío disponible el próximo día Sábado.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenCampoDialog(false)} color="primary">
            Entendido
          </Button>
        </DialogActions>
      </Dialog>
    </section>
  );
};
