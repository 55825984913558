import axios from 'axios';
import { API_URL } from '../const/api';

const API = `${API_URL}/newsletter`; // Cambia la URL según tu configuración
export const newsletter = async (email) => {
  try {
    const response = await axios.post(API, email, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error en la solicitud:', error);
    throw error;
  }
};