import React, { useState } from 'react';
import { TextField, Button, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { forgotPassword } from "../../client/apiAuth";

export const ForgotPasswordPage = () => {
  const [email, setEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async () => {
    try {
      await forgotPassword(email);
      setIsSubmitted(true);
    } catch (error) {
      // Verifica si el mensaje de error es "Client not found"
      if (error.response && error.response.data.message === "Client not found") {
        setErrorMessage('El correo ingresado no existe en nuestro sistema.');
      } else {
        setErrorMessage('Error enviando el correo de recuperación. Por favor, intenta nuevamente.');
      }
      setErrorDialogOpen(true);
    }
  };

  const handleCloseErrorDialog = () => {
    setErrorDialogOpen(false);
  };

  return (
    <div style={{ maxWidth: '400px', margin: '0 auto', padding: '20px' }}>
      <h2>Olvidaste tu contraseña</h2>
      
      {!isSubmitted ? (
        <>
          <Typography>
            Ingresa tu dirección de correo electrónico y te enviaremos instrucciones para restablecer tu contraseña.
          </Typography>
          
          <TextField
            label="Email"
            type="email"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            margin="normal"
          />
          
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSubmit}
            style={{ marginTop: '20px' }}
          >
            Enviar
          </Button>
        </>
      ) : (
        <Typography>
          Se ha enviado un correo de recuperación a <strong>{email}</strong>. Por favor, revisa tu bandeja de entrada y sigue las instrucciones para restablecer tu contraseña.
        </Typography>
      )}

      {/* Diálogo de error */}
      <Dialog open={errorDialogOpen} onClose={handleCloseErrorDialog}>
        <DialogTitle>
          <ErrorOutlineIcon style={{ color: 'red', marginRight: '10px', verticalAlign: 'middle' }} />
          Error
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{errorMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseErrorDialog} color="primary">OK</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
