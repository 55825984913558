import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  useMediaQuery,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { getOrdersConsolidated, updateConsolidated } from "../../client/apiConsolidated";
import { useTheme } from "@mui/material/styles";

export const ConsolidatedOrders = () => {
  const [purchasedQuantities, setPurchasedQuantities] = useState({});
  const [costs, setCosts] = useState({});
  const [consolidatedOrders, setConsolidatedOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [loadingSku, setLoadingSku] = useState(null);
  const [dialog, setDialog] = useState({ open: false, title: "", message: "" });
  const [search, setSearch] = useState("");
  const [filterType, setFilterType] = useState("");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const fetchOrders = async () => {
    try {
      const response = await getOrdersConsolidated();
      setConsolidatedOrders(response);
      setFilteredOrders(response);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  const handleFilterChange = () => {
    let orders = [...consolidatedOrders];

    if (search) {
      orders = orders.filter((order) =>
        order.productName.toLowerCase().includes(search.toLowerCase())
      );
    }

    if (filterType) {
      if (filterType === "Etiqueta Verde") {
        orders = orders.filter((order) => order.productType !== "Convencional");
      } else if (filterType === "Convencional") {
        orders = orders.filter((order) => order.productType === "Convencional");
      }
    }

    setFilteredOrders(orders);
  };

  useEffect(() => {
    handleFilterChange();
  }, [search, filterType]);

  const handleQuantityChange = (sku, value) => {
    setPurchasedQuantities((prevQuantities) => ({
      ...prevQuantities,
      [sku]: value,
    }));
  };

  const handleCostChange = (sku, value) => {
    setCosts((prevCosts) => ({
      ...prevCosts,
      [sku]: value,
    }));
  };

  const handleSubmitQuantity = async (order) => {
    setLoadingSku(order.sku);
    try {
      const dataToSend = {
        sku: order.sku,
        purchasedQuantity: Number(purchasedQuantities[order.sku]) || 0,
        cost: Number(costs[order.sku]) || 0,
        detailIds: order.detailIds,
        id: order.id,
      };
      const response = await updateConsolidated(dataToSend);

      if (response && !response.error) {
        setDialog({
          open: true,
          title: "Éxito",
          message: `Datos para SKU ${order.sku} enviados correctamente.`,
        });
        setConsolidatedOrders((prevOrders) =>
          prevOrders.filter((o) => o.sku !== order.sku)
        );
        setFilteredOrders((prevOrders) =>
          prevOrders.filter((o) => o.sku !== order.sku)
        );
        setPurchasedQuantities((prevQuantities) => ({
          ...prevQuantities,
          [order.sku]: "",
        }));
        setCosts((prevCosts) => ({
          ...prevCosts,
          [order.sku]: "",
        }));
      } else {
        setDialog({
          open: true,
          title: "Error",
          message: `Hubo un error al enviar los datos para SKU ${order.sku}.`,
        });
      }
    } catch (error) {
      console.error(`Error al enviar datos para SKU ${order.sku}:`, error);
      setDialog({
        open: true,
        title: "Error",
        message: `Hubo un error al enviar los datos para SKU ${order.sku}.`,
      });
    } finally {
      setLoadingSku(null);
    }
  };

  const handleCloseDialog = () => {
    setDialog({ open: false, title: "", message: "" });
  };

  return (
    <Box sx={{ width: "100%", maxWidth: 800, mx: "auto", p: 2 }}>
      <Typography variant="h4" align="center" sx={{ mb: 4 }}>
        Órdenes Consolidadas
      </Typography>
      <Box sx={{ display: "flex", flexDirection: isMobile ? "column" : "row", gap: 2, mb: 3 }}>
        <TextField
          label="Buscar por nombre"
          variant="outlined"
          size="small"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          sx={{ flex: 1 }}
        />
        <FormControl size="small" sx={{ flex: 1, minWidth: isMobile ? "100%" : 200 }}>
          <InputLabel>Filtrar por tipo</InputLabel>
          <Select
            value={filterType}
            onChange={(e) => setFilterType(e.target.value)}
            label="Filtrar por tipo"
          >
            <MenuItem value="">Todos</MenuItem>
            <MenuItem value="Convencional">Convencional</MenuItem>
            <MenuItem value="Etiqueta Verde">Etiqueta Verde</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Grid container spacing={2}>
        {filteredOrders.map((order) => (
          <Grid item xs={12} key={order.sku}>
            <Card
              sx={{
                border: "1px solid #4caf50",
                boxShadow: "0 3px 5px rgba(0, 0, 0, 0.2)",
                "&:hover": {
                  boxShadow: "0 6px 10px rgba(0, 0, 0, 0.3)",
                },
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  justifyContent: "space-between",
                  alignItems: isMobile ? "flex-start" : "center",
                  gap: 2,
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1, width: isMobile ? "100%" : "auto" }}>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Typography variant="body2"><strong>SKU:</strong> {order.sku}</Typography>
                    <Typography
                      variant="caption"
                      sx={{
                        bgcolor: order.productType === "Convencional" ? "orange" : "green",
                        color: "white",
                        borderRadius: 1,
                        px: 1,
                        py: 0.5,
                      }}
                    >
                      {order.productType}
                    </Typography>
                  </Box>
                  <Typography variant="body2"><strong>Producto:</strong> {order.productName}</Typography>
                  <Typography variant="body2"><strong>Cantidad a Comprar:</strong> {order.totalConsolidated}</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row",
                    alignItems: "center",
                    gap: 2,
                    width: isMobile ? "100%" : "auto",
                  }}
                >
                  <TextField
                    label="Comprada"
                    type="number"
                    variant="outlined"
                    size="small"
                    value={purchasedQuantities[order.sku] || ""}
                    onChange={(e) => handleQuantityChange(order.sku, e.target.value)}
                    sx={{ width: isMobile ? "100%" : 120 }}
                    inputProps={{ min: 0 }}
                  />
                  <TextField
                    label="Costo"
                    type="number"
                    variant="outlined"
                    size="small"
                    value={costs[order.sku] || ""}
                    onChange={(e) => handleCostChange(order.sku, e.target.value)}
                    sx={{ width: isMobile ? "100%" : 120 }}
                    inputProps={{ min: 0, step: "0.01" }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleSubmitQuantity(order)}
                    sx={{ width: isMobile ? "100%" : "auto" }}
                    disabled={loadingSku === order.sku}
                  >
                    {loadingSku === order.sku ? <CircularProgress size={24} color="inherit" /> : "Confirmar"}
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Dialog open={dialog.open} onClose={handleCloseDialog}>
        <DialogTitle>{dialog.title}</DialogTitle>
        <DialogContent>
          <Typography>{dialog.message}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
