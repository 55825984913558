import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';
import { updateDirections } from '../../client/apiDirections'; // Importa la función para actualizar dirección

export const EditAddressModal = ({ open, handleClose, address, cities, regions, onSave }) => {
  console.log(address)
  const [formValues, setFormValues] = useState({
    address: '',
    number: '',
    city: '',
    region: '',
    department: '',
    additionalInfo: '',
  });

  useEffect(() => {
    if (address) {
      setFormValues({
        address: address.address || '',
        number: address.number || '',
        city: address.city || '',
        //region: address.region || '',
        department: address.department || '',
        additionalInfo: address.additionalInfo || '',
      });
    }
  }, [address]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSave = async () => {
    try {
      // Llamar a la API para actualizar la dirección
      const updatedAddress = {
        address: formValues.address,
        number: formValues.number,
        city: formValues.city,
        region: formValues.region,
        department: formValues.department,
        additionalInfo: formValues.additionalInfo,
      };

      await updateDirections(address.id, updatedAddress); // Actualiza la dirección con el ID
      onSave(updatedAddress); // Llama a la función onSave para realizar cualquier acción adicional
      handleClose();
    } catch (error) {
      console.error('Error actualizando la dirección:', error);
      // Muestra un mensaje de error o maneja el error según sea necesario
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>Editar Dirección</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Dirección"
              name="address"
              value={formValues.address}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Número"
              name="number"
              value={formValues.number}
              onChange={handleChange}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Ciudad</InputLabel>
              <Select
                name="city"
                value={formValues.city}
                onChange={handleChange}
                variant="outlined"
              >
                {cities.map((city) => (
                  <MenuItem key={city.id} value={city.name}>
                    {city.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Región</InputLabel>
              <Select
                name="region"
                value={formValues.region}
                onChange={handleChange}
                variant="outlined"
              >
                {regions.map((region) => (
                  <MenuItem key={region.id} value={region.name}>
                    {region.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid> */}
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Departamento o Casa</InputLabel>
              <Select
                name="department"
                value={formValues.department}
                onChange={handleChange}
                variant="outlined"
              >
                <MenuItem value="departamento">Departamento</MenuItem>
                <MenuItem value="casa">Casa</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Información Adicional"
              name="additionalInfo"
              value={formValues.additionalInfo}
              onChange={handleChange}
              variant="outlined"
              multiline
              rows={3}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancelar
        </Button>
        <Button onClick={handleSave} color="primary">
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
