// RegisterDialogs.js
import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  Box
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const RegisterDialogs = ({
  errorDialogOpen,
  onCloseErrorDialog,
  errorMessage,
  successDialogOpen,
  onCloseSuccessDialog,
  onSuccessAction
}) => {
  return (
    <>
      {/* Error Dialog */}
      <Dialog
        open={errorDialogOpen}
        onClose={onCloseErrorDialog}
        aria-labelledby="error-dialog-title"
        aria-describedby="error-dialog-description"
      >
        <DialogTitle id="error-dialog-title">Error</DialogTitle>
        <DialogContent>
          <Typography id="error-dialog-description">
            {errorMessage}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCloseErrorDialog} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Success Dialog */}
      <Dialog
        open={successDialogOpen}
        onClose={onCloseSuccessDialog}
        aria-labelledby="success-dialog-title"
        aria-describedby="success-dialog-description"
      >
        <DialogTitle id="success-dialog-title">
          <Box display="flex" alignItems="center">
            <CheckCircleIcon color="success" sx={{ mr: 1 }} />
            Registro exitoso
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography id="success-dialog-description">
            ¡Registro exitoso! Ya puedes iniciar sesión.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={onSuccessAction} 
            color="primary" 
            autoFocus
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RegisterDialogs;
