import React, { useState, useEffect } from 'react';
import { Typography, Card, CardContent, Box, Button, CircularProgress, Alert } from '@mui/material';
import { create } from '../../client/apiMemberships';
import { useNavigate } from 'react-router-dom';

export const TransferMembershipPage = () => {
  const [loading, setLoading] = useState(true); // Estado de carga
  const [error, setError] = useState(null); // Estado de error
  const [successMessage, setSuccessMessage] = useState(''); // Mensaje de éxito
  const [id, setId] = useState(''); // ID de la suscripción
  const [timer, setTimer] = useState(20); // Temporizador de 30 segundos
  const navigate = useNavigate();
  const membershipTypeId = parseInt(localStorage.getItem("membershipId"));
  const clientId = parseInt(localStorage.getItem("userId"));

  useEffect(() => {
    const initiateTransfer = async () => {
      try {
        const response = await create(membershipTypeId, clientId);
        console.log(response);
        setId(response.id);
        if (response) {
          setSuccessMessage('Suscripción pendiente de pago');
        } else {
          throw new Error(response.message || 'Error en la confirmación del pedido');
        }
      } catch (error) {
        setError(error.message || 'Hubo un problema al confirmar tu pedido');
      } finally {
        setLoading(false); // Finaliza la carga
      }
    };

    initiateTransfer();
  }, []);

  useEffect(() => {
    if (timer > 0) {
      const countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      return () => clearInterval(countdown);
    } else {
      navigate('/', { replace: true });
    }
  }, [timer, navigate]);

  const handleRedirectNow = () => {
    navigate('/', { replace: true });
  };

  const handleContact = () => {
    window.open('https://wa.me/973765050', '_blank');
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ maxWidth: 600, margin: 'auto', mt: 4 }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  if (successMessage) {
    return (
      <Card sx={{ maxWidth: 600, margin: 'auto', mt: 4 }}>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            <strong>#{id}</strong> - <strong>{successMessage}</strong>
          </Typography>
          <Typography variant="body1" gutterBottom>
            Hemos enviado un correo con los datos de transferencia bancaria. Por favor confirmanos enviando el comprobante para activar tu membresía.
            Si tienes alguna duda, contactanos por WhatsApp y te responderemos a la brevedad.
          </Typography>

          {/* Mensaje de redirección con estilo */}
          <Typography
            variant="body2"
            align="center"
            sx={{
              mt: 2,
              backgroundColor: 'red',
              color: 'white',
              fontWeight: 'bold',
              padding: '8px',
              borderRadius: '4px',
            }}
          >
            Serás redirigido a la página principal en {timer} segundos.
          </Typography>

          {/* Botones de WhatsApp y Redirección */}
          <Box display="flex" justifyContent="center" gap={2} mt={3}>
            <Button variant="contained" color="success" onClick={handleContact}>
              Contactar por WhatsApp
            </Button>
            <Button variant="outlined" color="primary" onClick={handleRedirectNow}>
              Ir al inicio
            </Button>
          </Box>
        </CardContent>
      </Card>
    );
  }

  return null; // Si no hay ni error ni éxito, no muestra nada (esto solo debe ocurrir mientras carga)
};
